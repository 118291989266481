import { API } from "shared/api";
import { queries } from "shared/api/queries";
import { IPaginationResponse } from "shared/types/api";
import { IGenerationSource } from "shared/types/source";


export interface IListSourcesArgs {
  page: number
  perPage: number
  usState?: string | null
  fuelCategory?: string | null
  nameSearch?: string | null
  customerId?: string | null
  assetTypes?: string[] | null
}

export interface ISourcesFacets {
  fuel_categories: Record<string, number>,
  us_states: Record<string, number>
}

const sourcesPage: {type: 'Source', id: string} = { type: 'Source', id: 'PAGE' }

export const fetchSourcesPage = (qs: string) => API.get<IPaginationResponse<IGenerationSource>>(`/api/v2/sources/?${qs}`);
export const fetchSourceFacetsPage = (qs: string) => API.get<{data: ISourcesFacets}>(`/api/v2/sources/facets/?${qs}`);

const sourcesQueries = queries.injectEndpoints({
  endpoints: (build) => ({
    paginateSources: build.query<IPaginationResponse<IGenerationSource>, IListSourcesArgs>({
      query: (body) => {
        const qs = new URLSearchParams();
        qs.set('page', body.page.toString());
        qs.set('per_page', body.perPage.toString());
        if (body.usState) {
          qs.set('us_state', body.usState);
        }

        if (body.fuelCategory) {
          qs.set('fuel_category', body.fuelCategory);
        }

        if (body.nameSearch) {
          qs.set('name', body.nameSearch);
        }

        if (body.customerId) {
          qs.set('customer_id', body.customerId);
        }

        return {
          url: `/v2/sources/?${qs}`,
          method: 'GET',
        }
      },

      providesTags: (res) => res ? [sourcesPage, ...res.data.map(source => ({ type: 'Source' as const, id: source.id }))] : [],
    }),

    fetchSource: build.query<{ source: IGenerationSource }, {id: string, customerId?: string | null}>({
      query: ({id, customerId}) => `/v2/sources/${id}?${customerId ? `customer_id=${customerId}` : ''}`,
      providesTags: (res) => res ? [{ type: 'Source', id: res.source.id }] : [],
    }),

  }),
  overrideExisting: false,
});


export const {
  usePrefetch,
  usePaginateSourcesQuery,
  useFetchSourceQuery,
} = sourcesQueries;