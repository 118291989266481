import { Button, Group } from "@mantine/core";
import { IconPlus, IconTrash } from "@tabler/icons-react";
import { useAdminDeleteParentMutation, useAdminPaginateChildrenQuery } from "admin/api/customers";
import AddChildCustomerModal from "admin/components/AddChildCustomerModal";
import AdminViewContainer from "admin/components/ViewContainer";
import { useAmpNav } from "amp/hooks";
import { MouseEvent, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import BaseTable from "shared/components/Table/baseTable";


const tableColumns = [
  {
    key: 'name',
    displayValue: 'Name',
  },
  {
    key: 'id',
    displayValue: 'ID',
  },
  {
    key: 'customerType',
    displayValue: 'Customer Type',
  },
  {
    key: 'delete',
    displayValue: 'Delete'
  }
]

const CustomerChildrenView = () => {
  const { customerId='' } = useParams<{customerId: string}>();
  const [params, setSearchParams] = useSearchParams();
  const nav = useAmpNav();
  const [addChildModalOpen, setAddChildModalOpen] = useState(false);
  const [deleteChild] = useAdminDeleteParentMutation();

  const page = isNaN(parseInt(params.get('p') || '1')) ? 1 : parseInt(params.get('p') || '1');
  const perPage = isNaN(parseInt(params.get('ps') || '10')) ? 10 : parseInt(params.get('ps') || '10');

  const {
    isLoading,
    isFetching,
    data,
  } = useAdminPaginateChildrenQuery({
    page,
    perPage,
    customerId,
  });

  const onPageChange = (newPage: number) => {
    setSearchParams(newParams => {
      newParams.set('p', newPage.toString());
      return newParams;
    });
  };

  const onClickRow = (customerId: string) => {
    nav(`/admin/customers/${customerId}`);
  };

  const onClickDelete = (e: MouseEvent, childId: string) => {
    e.stopPropagation();
    deleteChild({childId, parentId: customerId});
  }

  const pagination = data?.meta.pagination;
  const customers = data?.data || [];

  return <AdminViewContainer title="Child Customers" actions={<Group mb={16}>
    <Button size='xs' onClick={() => setAddChildModalOpen(true)} leftSection={<IconPlus size={12} />}>Add child</Button>
  </Group>}>
    <BaseTable
      rows={customers.map(customer => ({
        id: customer.child_customer_id,
        name: customer.child_customer_name,
        customerType: customer.child_customer_type || <em>Unspecified</em>,
        delete: <Button leftSection={<IconTrash size={12} />} size="xs" color="var(--color-red-0)" onClick={(e) => onClickDelete(e, customer.child_customer_id)}>Delete</Button>,
      }))}
      onTableRowClicked={onClickRow}
      isLoading={isLoading || isFetching}
      columnNames={tableColumns}
      totalPages={pagination?.last || 0}
      totalItems={pagination?.total_items || 0}
      currentPage={page}
      onPageChange={onPageChange}
    />
    <AddChildCustomerModal isOpen={addChildModalOpen} onClose={() => setAddChildModalOpen(false)} parentId={customerId} />
  </AdminViewContainer>
};

export default CustomerChildrenView;