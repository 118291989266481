import { Checkbox, Group, Select, Skeleton, Text, TextInput } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';
import { sum } from 'ramda';
import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useBulkFetchGeneratorsQuery, useFetchGenerationQuery } from 'amp/api/generators';
import { useGenerator, useUtilitySources } from 'amp/store/generators/hooks';
import { getViewingOpCoId } from 'amp/store/ui/selectors';
import BaseTable, { IBaseTableColumn } from 'shared/components/Table/baseTable';
import { IUtilityGenerationData } from 'shared/types/assetEvents';
import { GeneratorFuelCategories, IGenerator, stateValueToName, usStates } from 'shared/types/generator';
import { IGenerationSource } from 'shared/types/source';
import { getLastYearEnd, getLastYearStart } from 'shared/utils/dates';
import { numberToString, snakeToTitle } from 'shared/utils/strings';
import { useAppSelector } from 'store';
import './style.css';

const generatorTableColumns: IBaseTableColumn[] = [
  { key: 'name', displayValue: 'Name' },
  { key: 'description', displayValue: 'Description' },
  { key: 'location', displayValue: 'Location' },
  { key: 'fuelCategory', displayValue: 'Fuel Category' },
  { key: 'lastYearGeneration', displayValue: 'Prior Year Generation' },
];

const LastYearGenerationCell = ({ generatorId }: { generatorId: string }) => {
  const generatorRes = useGenerator(generatorId);
  const generator = generatorRes.data;

  const startDate = getLastYearStart().toISOString();
  const endDate = getLastYearEnd().toISOString();
  const res = useFetchGenerationQuery(
    { startDate, endDate, resolution: '1d', generatorIds: [generatorId], customerIds: [generator?.customer_id || ''] },
    { skip: !generator || !generator?.customer_id }
  );

  const totalGeneration = sum(res.data?.data.map(ae => (ae.data as IUtilityGenerationData).sum_generated_wh) || []);
  const totalGenerationMWh = totalGeneration / 1_000_000;
  return (
    <Skeleton ta="right" visible={res.isLoading} maw={200}>
      <Text fz={10} fw={700} ta="right">
        {numberToString(Math.round(totalGenerationMWh))} MWh
      </Text>
    </Skeleton>
  );
}

const GeneratorSelection = () => {
  const [params, setParams] = useSearchParams();
  const oci = useAppSelector(getViewingOpCoId);
  const page = isNaN(parseInt(params.get('p') || '1')) ? 1 : parseInt(params.get('p') || '1');
  const perPage = isNaN(parseInt(params.get('ps') || '10')) ? 10 : parseInt(params.get('ps') || '10');
  const fuelCategory = params.get('fc');
  const usState = params.get('us');
  const nameSearch = params.get('ns') || '';
  const selectedGenerators = params.getAll('sg');
  const [nameSearchStr, setNameSearchStr] = useState<string>(nameSearch || '');
  const [typingTimer, setTypingTimer] = useState<ReturnType<typeof setTimeout> | undefined>();
  const res = useUtilitySources({ page, perPage, fuelCategory, usState, nameSearch, assetTypes: ['generator'] });
  const selectedGensRes = useBulkFetchGeneratorsQuery({ids: selectedGenerators, customerId: oci});

  const onPageChange = (newPage: number) => {
    setParams((params) => {
      params.set('p', newPage.toString());
      return params;
    });
  };

  const onSearchChange = (value: string) => {
    setNameSearchStr(value);

    if (!value) {
      clearTimeout(typingTimer);
      setParams(newParams => {
        newParams.delete('ns');
        return newParams;
      });
    } else {
      clearTimeout(typingTimer);
      setTypingTimer(setTimeout(() => {
        setParams(newParams => {
          newParams.set('ns', value);
          newParams.set('p', '1');
          return newParams;
        });
      }, 400));
    }
  };

  const onFuelCategoryChange = (newCategory: string | null) => {
    if (!newCategory) {
      setParams(newParams => {
        newParams.delete('fc');
        return newParams;
      });
    } else {
      setParams(newParams => {
        newParams.set('p', '1');
        newParams.set('fc', newCategory);
        return newParams;
      });
    }
  };

  const onUsStateChange = (newState: string | null) => {
    if (!newState) {
      setParams(newParams => {
        newParams.delete('us');
        return newParams;
      });
    } else {
      setParams(newParams => {
        newParams.set('us', newState);
        newParams.set('p', '1');
        return newParams;
      });
    }
  };

  const onRowToggled = (checked: boolean, checkedGenId: string) => {
    if (!checked) {
      setParams(newParams => {
        const gens = newParams.getAll('sg');
        // TODO: delete should support deleting by value but caused errors when given a 2nd arg
        newParams.delete('sg');
        gens.forEach(gid => {
          if (gid !== checkedGenId) {
            newParams.append('sg', gid);
          }
        });
        return newParams;
      });
    } else {
      setParams(newParams => {
        newParams.append('sg', checkedGenId);
        return newParams;
      });
    }
  }

  const toggleRowSelected = (rowId: string) => {
    onRowToggled(!selectedGenerators.includes(rowId), rowId);
  };

  const rows = useMemo(() => {
    const generators: IGenerationSource[] = (selectedGensRes.data?.data || [] as IGenerationSource[])
      .filter(gen => selectedGenerators.includes(gen.id))
      .concat((res.data || []).filter(row => !selectedGenerators.includes(row.id)));

    return generators.map((row) => {
      const asGenerator = row as IGenerator;
      return {
        id: row.id,
        name: <Group gap={8}>
          <Checkbox
            size="16px"
            color="var(--color-green-1)"
            checked={selectedGenerators.includes(row.id)}
          />
          <div className="projections-page--name-table-cell">{row.name}</div>
        </Group>,
        description: row.description || '',
        location: stateValueToName[(asGenerator.location.us_state) || ''] || 'Unknown',
        fuelCategory: snakeToTitle(asGenerator.meta.fuel_category || 'Unspecified'),
        lastYearGeneration: <LastYearGenerationCell generatorId={row.id} />,
      }
    }) || [];
  }, [res.data, selectedGenerators, selectedGensRes]);

  return (
    <div className="projections-page--selection-area">
      <div className="projections-page--filters-container">
        <TextInput
          placeholder="Search..."
          value={nameSearchStr}
          onChange={e => onSearchChange(e.target.value)}
          rightSection={<IconSearch style={{ width: 16, height: 16 }} />}
          aria-label="Generator search input"
        />
        <Select
          placeholder="Filter by fuel..."
          value={fuelCategory}
          onChange={onFuelCategoryChange}
          data={Object.values(GeneratorFuelCategories).map(category => ({ label: snakeToTitle(category), value: category }))}
          aria-label="Generator fuel category selection"
          clearable
        />
        <Select
          placeholder="Filter by location..."
          value={usState}
          onChange={onUsStateChange}
          data={Object.values(usStates).map(state => ({ label: state.name, value: state.value }))}
          aria-label="Generator US State selection"
          clearable
        />
      </div>
      <BaseTable
        rows={rows}
        onTableRowClicked={toggleRowSelected}
        columnNames={generatorTableColumns}
        totalPages={res.pagination?.last || 0}
        currentPage={page}
        onPageChange={onPageChange}
        isLoading={res.isLoading}
      />
    </div>
  );
}

export default GeneratorSelection;