import { Avatar, Box, Button, Group, Text, Title } from '@mantine/core';
import { IconThumbDown, IconThumbUp } from '@tabler/icons-react';
import { useMemo, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { useListReviewsForAllocationQuery } from 'amp/api/allocationRuns';
import PageBreadcrumbs from 'amp/components/PageBreadcrumbs/pageBreadcrumbs';
import Tabs from 'amp/components/Tabs';
import { useAllocationRun } from 'amp/store/allocationRuns/hooks';
import { getAllocationRunStartAndEnd } from 'amp/store/allocationRuns/selectors';
import { getViewingOpCoId } from 'amp/store/ui/selectors';
import { useBulkFetchUsersQuery } from 'shared/api/users';
import RoleRequired from 'shared/components/RoleRequired/roleRequired';
import { getCurrentUser, getHasRolePermission } from 'shared/store/user/selectors';
import { AllocationRunStatus, AllocationSettlementReviewStatus, getAllocationStatusText, IAllocationRun, IAllocationSettlementReview } from 'shared/types/allocation';
import { IUser, UserRole } from 'shared/types/user';
import { toNumericDateString } from 'shared/utils/dates';
import { useAppSelector } from 'store';
import ApprovalModal from './approvalModal';
import DenialModal from './denialModal';
import SelectReviewersModal from './selectReviewersModal';
import SettlementModal from './settlementModal';
import './style.css';
import ViewReviewersModal from './viewReviewersModal';


export default function AllocationRunOutlet() {
  const { runId = '' } = useParams<{ runId: string }>();
  const [selectReviewersModalOpen, setSelectReviewersModalOpen] = useState<boolean>(false);
  const [viewReviewersModalOpen, setViewReviewersModalOpen] = useState<boolean>(false);
  const [approvalModalOpen, setApprovalModalOpen] = useState<boolean>(false);
  const [denialModalOpen, setDenialModalOpen] = useState<boolean>(false);
  const [settlementModalOpen, setSettlementModalOpen] = useState<boolean>(false);

  const oci = useAppSelector(getViewingOpCoId);
  const isAdmin = useAppSelector(s => getHasRolePermission(s, UserRole.ADMIN));
  const currentUser = useAppSelector(s => getCurrentUser(s));
  const allocationRunRes = useAllocationRun(runId, oci);
  const allocationRun = allocationRunRes.data;
  const startAndEnd = useAppSelector(s => getAllocationRunStartAndEnd(s, runId));

  // TODO: could combine fetching reviews and reviewers in a single hook to consolidate if we do this more places.
  const settlementReviewsResp = useListReviewsForAllocationQuery({ id: runId, customerId: oci });
  const settlementReviews = settlementReviewsResp.data?.data || [];
  const reviewerIds = settlementReviews.map(sr => sr.reviewer_id);
  const reviewersRes = useBulkFetchUsersQuery(reviewerIds, { skip: !reviewerIds.length });

  const reviewersById = useMemo(() => {
    const reviewers: Record<string, IUser> = {};
    reviewersRes.data?.data.forEach(reviewer => reviewers[reviewer.id] = reviewer);
    return reviewers;
  }, [reviewersRes.data]);

  const tabs = [
    { title: 'Overview', path: `/dashboard/allocation/${runId}` },
    { title: 'Customers', path: `/dashboard/allocation/${runId}/customers` },
  ];

  if (isAdmin) {
    tabs.push({ title: 'Diagnostic', path: `/dashboard/allocation/${runId}/diagnostic` })
  }

  let title = "Allocation";
  if (startAndEnd?.end) {
    title = `Allocation ${toNumericDateString(startAndEnd.start)} - ${toNumericDateString(startAndEnd.end)}`;
  }

  const makeAllocationStatusText = (allocationRun: IAllocationRun) => {
    if (!allocationRun) {
      return <></>
    }

    const { text, background, color } = getAllocationStatusText(allocationRun);
    return <Box bg={background} className="allocation-row--status-box">
      <Text fz={12} c={color}>{text}</Text>
    </Box>
  }

  const isApproved = allocationRun?.status === AllocationRunStatus.SETTLEMENT_APPROVED;
  const isDeclined = allocationRun?.status === AllocationRunStatus.SETTLEMENT_REJECTED;
  const userIsReviewing = settlementReviews.some((sr: IAllocationSettlementReview) => sr.reviewer_id === currentUser.id);
  const currentUserReview = settlementReviews.find(sr => sr.reviewer_id === currentUser.id);

  return (
    <div>
      <div className="allocation-run-outlet--container">
        <PageBreadcrumbs />
        <Group p={0} justify="space-between">
          <Group>
            <Title size="24px" mt="16px" mb="16px">{title}</Title>
            {makeAllocationStatusText(allocationRun)}
          </Group>
          <RoleRequired role={UserRole.ADMIN}>
            <Group>
              {allocationRun?.status === AllocationRunStatus.FINISHED && allocationRun?.can_be_settled === true &&
                <Button bg="var(--color-black-3)" c="white" onClick={() => setSelectReviewersModalOpen(true)}>
                  Request Approval
                </Button>
              }
              {allocationRun?.status === AllocationRunStatus.IN_SETTLEMENT_REVIEW && userIsReviewing &&
                <Group>
                  <Text>Do you approve the allocation?</Text>
                  <Button
                    className="allocation-run-outlet--approval-button yes"
                    rightSection={<IconThumbUp size={16} />}
                    onClick={() => setApprovalModalOpen(true)}
                    bg={currentUserReview?.status === AllocationSettlementReviewStatus.APPROVED ? "var(--color-green-2)" : undefined}
                    c={currentUserReview?.status === AllocationSettlementReviewStatus.APPROVED ? "#FFFFFF" : undefined}
                  >
                    YES
                  </Button>
                  <Button
                    className="allocation-run-outlet--approval-button no"
                    rightSection={<IconThumbDown size={16} />}
                    onClick={() => setDenialModalOpen(true)}
                    bg={currentUserReview?.status === AllocationSettlementReviewStatus.REJECTED ? "#C92A2A" : undefined}
                    c={currentUserReview?.status === AllocationSettlementReviewStatus.REJECTED ? "#FFFFFF" : undefined}
                  >
                    NO
                  </Button>
                </Group>
              }
              {(allocationRun?.status === AllocationRunStatus.IN_SETTLEMENT_REVIEW || isApproved || allocationRun?.status === AllocationRunStatus.SETTLEMENT_REJECTED) &&
                <Button
                  onClick={() => setViewReviewersModalOpen(true)}
                  className={`allocation-run-outlet--in-review-button ${isApproved ? 'approved' : undefined} ${isDeclined ? 'declined' : undefined}`}
                  rightSection={<Avatar.Group spacing="xs">
                    {settlementReviews.slice(0, 3).map(sr => (
                      <Avatar key={sr.id} color="white" bg="var(--color-blue-3)" size={28}>
                        {reviewersById[sr.reviewer_id]?.name.slice(0, 1).toLocaleUpperCase() || ''}
                      </Avatar>
                    ))}
                  </Avatar.Group>}
                >
                  See Approvers
                </Button>
              }
              {/* TODO: add a case for AUTO_REJECTED, in designs we have a "delete allocation" button, but idk if we should do that */}
              {allocationRun?.status === AllocationRunStatus.SETTLEMENT_APPROVED &&
                <Button
                  bg="var(--color-black-2)"
                  c="white"
                  onClick={() => setSettlementModalOpen(true)}
                >
                  Settle Allocation
                </Button>
              }
            </Group>
          </RoleRequired>
        </Group>
        <Tabs tabs={tabs} />
      </div>
      {selectReviewersModalOpen && <SelectReviewersModal isOpen={selectReviewersModalOpen} onClose={() => setSelectReviewersModalOpen(false)} runId={runId} />}
      {viewReviewersModalOpen && <ViewReviewersModal isOpen={viewReviewersModalOpen} onClose={() => setViewReviewersModalOpen(false)} runId={runId} />}
      {approvalModalOpen && <ApprovalModal isOpen={approvalModalOpen} onClose={() => setApprovalModalOpen(false)} reviewId={currentUserReview?.id || ''} />}
      {denialModalOpen && <DenialModal isOpen={denialModalOpen} onClose={() => setDenialModalOpen(false)} reviewId={currentUserReview?.id || ''} />}
      {settlementModalOpen && <SettlementModal isOpen={settlementModalOpen} onClose={() => setSettlementModalOpen(false)} runId={runId} />}
      <Outlet />
    </div>
  );
}