import { Avatar, Box, Button, Center, Group, Loader, Modal, Select, Stack, Text, TextInput } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconSearch, IconTrash, IconX } from '@tabler/icons-react';
import { useMemo, useState } from 'react';

import { useCreateAllocationReviewersMutation } from 'amp/api/allocationRuns';
import { getViewingOpCoId } from 'amp/store/ui/selectors';
import { usePaginateTeammatesQuery } from 'shared/api/users';
import { IUser } from 'shared/types/user';
import { useAppSelector } from 'store';

enum RequestReviewWorkflowSteps {
  selectReviewers = 1,
  confirmReviewers = 2,
}

const SelectReviewersModal = ({
  onClose,
  isOpen,
  runId,
}: { onClose: () => void, isOpen: boolean, runId: string }) => {
  const [workflowStep, setWorkflowStep] = useState(RequestReviewWorkflowSteps.selectReviewers);
  const [confirmText, setConfirmText] = useState<string>('');
  const [selectedReviewers, setSelectedReviewers] = useState<Set<string>>(new Set());
  const [inviteReviewers, inviteReviewersRes] = useCreateAllocationReviewersMutation();

  const oci = useAppSelector(getViewingOpCoId);

  // TODO: handle case with over 50 teammates
  // TODO: what about getting teammates of child customers (e.g. SOCO user invites GPC user)
  const myTeammatesRes = usePaginateTeammatesQuery({ page: 1, perPage: 50 });
  const myTeammates = myTeammatesRes.data?.data || [];

  const teammatesById = useMemo(() => {
    const teammates: Record<string, IUser> = {};
    myTeammatesRes.data?.data.forEach(t => teammates[t.id] = t);
    return teammates;
  }, [myTeammatesRes.data]);

  const onRequestSent = () => {
    inviteReviewers({
      runId,
      customerId: oci,
      data: {
        include_self: false,
        send_review_email_to_self: true,
        reviewer_user_ids: Array.from(selectedReviewers),
      }
    }).unwrap().then(() => {
      notifications.show({
        title: 'Success',
        message: 'Successfully invited users to review',
        color: "teal",
        icon: <IconCheck style={{ width: '20px', height: '20px' }} />,
      });
      onClose();
    }).catch(() => notifications.show({
      title: 'Error',
      message: 'Failed to invite users',
      icon: <IconX style={{ width: '20px', height: '20px' }} />,
      color: "red",
    }))
  }

  const onTeammateSelected = (userId: string | null) => {
    if (!userId) {
      return;
    }

    const newSelectedReviewers = new Set(selectedReviewers);
    newSelectedReviewers.add(userId);
    setSelectedReviewers(newSelectedReviewers);
  }

  const onReviewerRemoved = (userId: string) => {
    const newSelectedReviewers = new Set(selectedReviewers);
    newSelectedReviewers.delete(userId);
    setSelectedReviewers(newSelectedReviewers);
  }

  const isLoading = myTeammatesRes.isLoading || myTeammatesRes.isFetching;
  const sendDisabled = confirmText.toLowerCase() !== 'send request';
  return (
    <Modal
      onClose={onClose}
      opened={isOpen}
      title={workflowStep === 1 ? 'Request Approval' : 'Send Request Confirmation'}
    >
      {isLoading && <>
        <Box w="100%" h="200px">
          <Center>
            <Loader />
          </Center>
        </Box>
      </>}
      {!isLoading && workflowStep === RequestReviewWorkflowSteps.selectReviewers && <>
        <Stack gap={40}>
          <Select
            searchable
            rightSection={<IconSearch size={16} />}
            label="Choose Approvers"
            data={myTeammates.map(t => ({ value: t.id, label: t.name }))}
            onChange={e => onTeammateSelected(e)}
          />
          <Stack>
            {Array.from(selectedReviewers).map(sr => (
              <Group justify="space-between" key={sr}>
                <Group>
                  <Avatar color="white" bg="black" size={28}>
                    {teammatesById[sr]?.name.slice(0, 1).toLocaleUpperCase()}
                  </Avatar>
                  <Text>{teammatesById[sr]?.name || 'unknown name'}</Text>
                </Group>
                <IconTrash size={20} onClick={() => onReviewerRemoved(sr)} cursor="pointer" />
              </Group>
            ))}
          </Stack>
          <Group justify="space-between">
            <Button bg="var(--color-black-3)" c="white" maw={200} onClick={() => setWorkflowStep(RequestReviewWorkflowSteps.confirmReviewers)}>
              Next Step
            </Button>
            <Text fz={16} c="var(--color-grey-4)">Step 1/2</Text>
          </Group>
        </Stack>
      </>}
      {!isLoading && workflowStep === RequestReviewWorkflowSteps.confirmReviewers && <>
        <Stack>
          <Text fz={16} c="var(--color-neutral-12)">
            Once you send a request for approval of an allocation, you would not be able to remove approvers from the list. To modify approvers, you need create a new allocation with desired approvers and archive this allocation.
          </Text>
          <TextInput label="Type SEND REQUEST below to confirm" onChange={e => setConfirmText(e.target.value)} />
          <Group justify="space-between">
            <Button
              onClick={onRequestSent}
              loading={inviteReviewersRes.isLoading}
              disabled={sendDisabled}
              bg={sendDisabled ? undefined : "var(--color-black-3)"}
              c={sendDisabled ? undefined : "white"}
              maw={200}
            >
              Send Request
            </Button>
            <Text fz={16} c="var(--color-grey-4)">Step 2/2</Text>
          </Group>
        </Stack>
      </>}
    </Modal>
  );
}

export default SelectReviewersModal;