import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { endpoints, fetchAllocationRun, fetchAllocationRunInput, fetchRunsPage, IListAllocationRunsArgs } from 'amp/api/allocationRuns';
import { getViewingOpCoId } from 'amp/store/ui/selectors';
import { getOpcos } from 'shared/store/user/selectors';
import { IAllocationRun, IAllocationRunInputData, IAllocationRunUpdatePayload, IAllocationSummaryResult, ICustomerAllocationSummaryResult, IProgramAllocationSummaryResult, IStandardDeliveryResult } from 'shared/types/allocation';
import { IPagination, IPaginationResponse } from 'shared/types/api';
import { IAsyncDataSlice } from 'shared/types/store';
import { AppDispatch, RootState } from 'store';
import { getBulkFetchAllocationInputsLoading, getUtilityRunsPageLoading } from './selectors';


type BulkFetchAllocationRunInputsResponse = {
  data?: Record<string, IAllocationRunInputData>,
  status: 'SUCCESS' | 'FAILED',
  serializedArgs: string,
};

interface IAllocationRunSlice {
  byId: Record<string, IAllocationRun>,
  summaryResultsById: Record<string, IAllocationSummaryResult>,
  inputsById: Record<string, IAllocationRunInputData>,
  customerResultsById: Record<string, Record<string, ICustomerAllocationSummaryResult>>,
  stdDeliveryResultsById: Record<string, IStandardDeliveryResult>,
  programResultsById: Record<string, Record<string, IProgramAllocationSummaryResult>>
  runsPageResponseByOCI: Record<string, IAsyncDataSlice<string[]>>,
  bulkFetchAllocationInputsResponse: IAsyncDataSlice<Record<string, IAllocationRunInputData>>,
}

const initialState: IAllocationRunSlice = {
  byId: {},
  summaryResultsById: {},
  inputsById: {},
  customerResultsById: {},
  stdDeliveryResultsById: {},
  programResultsById: {},
  runsPageResponseByOCI: {},
  bulkFetchAllocationInputsResponse: {
    data: {},
    pagination: null,
    isFetching: false,
    fetchFailed: false,
    lastReceived: null,
  },
}


export const fetchUtilityAllocationRuns = createAsyncThunk<{data?: IPaginationResponse<IAllocationRun>, oci: string, status: 'SUCCESS' | 'FAILED', serializedArgs: string} | undefined, IListAllocationRunsArgs, {
  dispatch: AppDispatch,
  state: RootState,
}>(
  'amp__allocation_runs/fetchUtilityAllocationRuns',
  async ({perPage, page, statuses}, {getState, dispatch}) => {
    const state = getState();
    const opcoId = getViewingOpCoId(state);
    const opcos = getOpcos(state);
    const customerIds = opcoId ? [opcoId] : opcos.map(d => d.id);
    const oci = customerIds.sort().join(',');
    const serializedArgs = JSON.stringify({page, perPage, statuses});
    dispatch(allocationRunsSlice.actions.initializeRunPageForOCI({oci, serializedArgs}));

    if (getUtilityRunsPageLoading(getState(), oci)) {
      // short circuit
      return undefined;
    }
    dispatch(allocationRunsSlice.actions.setRunsPageLoading({oci, isFetching: true}));

    const qs = new URLSearchParams();
    qs.set('per_page', perPage.toString());
    qs.set('page', page.toString());
    statuses?.forEach(status => {
      qs.append('statuses', status);
    })

    try {
      const runsById: Record<string, IAllocationRun> = {};
      let pagination: IPagination = {this: 1, last: 1, total_items: 0, first: 1};
      const runsRes = await Promise.all(customerIds.map(cid => fetchRunsPage(qs.toString() + `&customer_id=${cid}`)));
      runsRes.forEach(res => {
        pagination = res.data.meta.pagination;
        res.data.data.forEach(prog => {
          runsById[prog.id] = prog;
        });
      })
      return {data: {data: Object.values(runsById), meta: {pagination}}, status: 'SUCCESS', oci, serializedArgs};
    } catch (err) {
      return {status: 'FAILED', oci, serializedArgs};
    }
  },
);

export const fetchUtilityAllocationRunInputs = createAsyncThunk<BulkFetchAllocationRunInputsResponse | undefined, { allocationRuns: IAllocationRun[] }, {
  dispatch: AppDispatch,
  state: RootState,
}>(
  'amp__allocation_runs/fetchUtilityAllocationRunInputs',
  async ({ allocationRuns }, {getState, dispatch}) => {
    const asArgs = allocationRuns.map(run => ({ id: run.id, customer_id: run.customer_id })).sort((a, b) => `${a.customer_id}${a.id}`.localeCompare(`${b.customer_id}${b.id}`));
    const serializedArgs = JSON.stringify(asArgs);

    dispatch(allocationRunsSlice.actions.initializeBulkFetchAllocationInputs({ serializedArgs }));
    if (getBulkFetchAllocationInputsLoading(getState())) {
      // short circuit
      return undefined;
    }

    try {
      const allocationInputResponses = await Promise.all(asArgs.map(run => (fetchAllocationRunInput(run.id, run.customer_id)).then(resp => ({ response: resp, runId: run.id }))));
      const allocationInputsByRunId: Record<string, IAllocationRunInputData> = {};
      allocationInputResponses.forEach(inputRes => {
        inputRes && (allocationInputsByRunId[inputRes.runId] = inputRes.response.data);
      });

      return { data: allocationInputsByRunId, status: 'SUCCESS', serializedArgs};
    } catch (err) {
      return { status: 'FAILED', serializedArgs };
    }
  }
)


export const fetchAllocationRunUpdate = createAsyncThunk<{data?: IAllocationRun, status: 'SUCCESS' | 'FAILED'}, IAllocationRunUpdatePayload
>(
  'amp__allocation_runs/fetchAllocationRunUpdate',
  async ({ allocation_run_id, customer_id }) => {
    try {
      const runData = await fetchAllocationRun(allocation_run_id, customer_id)
      return {  data: runData.data.allocation_run, status: 'SUCCESS' };
    } catch (err) {
        return { status: 'FAILED' };
    }
  }
)

const allocationRunsSlice = createSlice({
  name: 'amp__allocation_runs',
  initialState,
  reducers: {
    receiveAllocationRuns: (state, action: PayloadAction<IAllocationRun[]>) => {
      action.payload.forEach(ar => {
        state.byId[ar.id] = ar;
      })
    },

    receiveAllocationRunSummary: (state, action: PayloadAction<IAllocationSummaryResult>) => {
      state.summaryResultsById[action.payload.allocation_run_id] = action.payload;
    },

    receiveAllocationRunInput: (state, action: PayloadAction<{inputData: IAllocationRunInputData, runId: string}>) => {
      state.inputsById[action.payload.runId] = action.payload.inputData;
    },

    receiveAllocationRunCustomerResult: (state, action: PayloadAction<{customerData: ICustomerAllocationSummaryResult, runId: string}>) => {
      if (!state.customerResultsById[action.payload.runId]) {
        state.customerResultsById[action.payload.runId] = {};
      }
      state.customerResultsById[action.payload.runId][action.payload.customerData.customer_id] = action.payload.customerData;
    },

    receiveStdDeliveryResult: (state, action: PayloadAction<{stdDeliveryData: IStandardDeliveryResult, runId: string}>) => {
      state.stdDeliveryResultsById[action.payload.runId] = action.payload.stdDeliveryData;
    },

    receiveAllocationRunProgramResult: (state, action: PayloadAction<{programData: IProgramAllocationSummaryResult, runId: string}>) => {
      if (!state.programResultsById[action.payload.runId]) {
        state.programResultsById[action.payload.runId] = {};
      }
      state.programResultsById[action.payload.runId][action.payload.programData.program_id] = action.payload.programData;
    },

    setRunsPageLoading: (state, action: PayloadAction<{oci: string, isFetching: boolean}>) => {
      state.runsPageResponseByOCI[action.payload.oci].isFetching = action.payload.isFetching;
    },

    initializeRunPageForOCI: (state, action: PayloadAction<{oci: string, serializedArgs: string}>) => {
      const {
        oci, serializedArgs,
      } = action.payload;
      if (!state.runsPageResponseByOCI[oci]) {
        state.runsPageResponseByOCI[oci] = {
          data: null,
          lastReceived: null,
          pagination: null,
          isFetching: false,
          fetchFailed: false,
          serializedArgs,
        }
      } else if (state.runsPageResponseByOCI[oci].serializedArgs !== serializedArgs) {
        state.runsPageResponseByOCI[oci].isFetching = false;
        state.runsPageResponseByOCI[oci].serializedArgs = serializedArgs;
      }
    },
    initializeBulkFetchAllocationInputs: (state, action: PayloadAction<{serializedArgs: string}>) => {
      const { serializedArgs } = action.payload;
      if (state.bulkFetchAllocationInputsResponse.serializedArgs !== serializedArgs) {
        state.bulkFetchAllocationInputsResponse.isFetching = false;
        state.bulkFetchAllocationInputsResponse.serializedArgs = serializedArgs;
      }
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchUtilityAllocationRuns.fulfilled, (state, action) => {
      // short circuited because a request was in-flight
      if (!action.payload) return;

      const ociState = state.runsPageResponseByOCI[action.payload.oci];

      // short circuited because a new request was dispatched
      if (ociState.serializedArgs !== action.payload.serializedArgs) return;

      ociState.isFetching = false;

      if (action.payload.data) {
        const runs = action.payload.data.data;
        ociState.data = runs.map(c => c.id);
        runs.forEach(run => {
          state.byId[run.id] = run;
        });
        ociState.fetchFailed = false;
        ociState.lastReceived = new Date();
        ociState.pagination = action.payload.data.meta.pagination;
      } else {
        ociState.fetchFailed = true;
      }
    });

    builder.addCase(fetchUtilityAllocationRunInputs.fulfilled, (state, action) => {
      // short circuited because a request was in-flight
      if (!action.payload) return;

      const bulkFetchInputsState = state.bulkFetchAllocationInputsResponse;

      // short circuited because a new request was dispatched
      if (bulkFetchInputsState.serializedArgs !== action.payload.serializedArgs) return;

      bulkFetchInputsState.isFetching = false;

      if (action.payload.data) {
        bulkFetchInputsState.data = action.payload.data;
        bulkFetchInputsState.fetchFailed = false;
        bulkFetchInputsState.lastReceived = new Date();
      } else {
        bulkFetchInputsState.fetchFailed = true;
      }
    });

    builder.addCase(fetchAllocationRunUpdate.fulfilled, (state, action) => {
      if (action.payload.data) {
        state.byId[action.payload.data.id] = action.payload.data;
      }
    });

    builder.addMatcher(endpoints.patchAllocationRun.matchFulfilled, (state, action) => {
      const runId = action.payload.allocation_run.id;
      Object.values(state.runsPageResponseByOCI).forEach(pageRes => {
        if (pageRes.data?.includes(runId)) {
          pageRes.fetchFailed = false;
          pageRes.lastReceived = null;
        }
      })
    });
  }
});


export const {
  receiveAllocationRuns,
  receiveAllocationRunSummary,
  receiveAllocationRunInput,
  receiveAllocationRunCustomerResult,
  receiveStdDeliveryResult,
  receiveAllocationRunProgramResult
} = allocationRunsSlice.actions;

export default allocationRunsSlice.reducer;