import { queries } from "shared/api/queries";
import { IPaginationResponse } from "shared/types/api";
import { IScheduledTask } from "shared/types/scheduledTask";


interface IListTasksArgs {
  page: number
  perPage: number
  status: string
}


const adminScheduledTasksQueries = queries.injectEndpoints({
  endpoints: (build) => ({
    adminPaginateScheduledTasks: build.query<IPaginationResponse<IScheduledTask>, IListTasksArgs>({
      query: (body) => {
        const qs = new URLSearchParams();
        qs.set('page', body.page.toString());
        qs.set('per_page', body.perPage.toString());
        qs.set('status', body.status);

        return {
          url: `/admin/scheduled_tasks/?${qs}`,
          method: 'GET',
        }
      },

      providesTags: res => res ? [{type: 'ScheduledTask', id: 'PAGE'}, ...res.data.map(task => ({id: task.id, type: 'ScheduledTask' as const}))] : [],
    }),

    adminFetchScheduledTask: build.query<{data: IScheduledTask}, string>({
      query: (id) => `/admin/scheduled_tasks/${id}`,

      providesTags: res => res ? [{type: 'ScheduledTask', id: res.data.id}] : [],
    }),

    adminResetScheduledTask: build.mutation<{data: IScheduledTask}, string>({
      query: (id) => ({
        url: `/admin/scheduled_tasks/${id}/reset`,
        method: 'POST'
      }),

      invalidatesTags: res => res ? [{type: 'ScheduledTask', id: res.data.id}, {type: 'ScheduledTask', id: 'PAGE'}] : [],
    }),

    adminSetScheduledTaskDone: build.mutation<{data: IScheduledTask}, string>({
      query: (id) => ({
        url: `/admin/scheduled_tasks/${id}/done`,
        method: 'POST'
      }),

      invalidatesTags: res => res ? [{type: 'ScheduledTask', id: res.data.id}, {type: 'ScheduledTask', id: 'PAGE'}] : [],
    }),
  }),
  overrideExisting: false,
});


export const {
  useAdminPaginateScheduledTasksQuery,
  useAdminFetchScheduledTaskQuery,
  useAdminResetScheduledTaskMutation,
  useAdminSetScheduledTaskDoneMutation,
} = adminScheduledTasksQueries;