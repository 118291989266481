import { API } from 'shared/api';
import { queries } from 'shared/api/queries';
import { IPaginationResponse } from 'shared/types/api';
import { IGeneratorAssignment, displayValuesToRequestData as assignDvtrd } from 'shared/types/assignment';
import { IProgram, displayValuesToRequestData } from 'shared/types/program';
import { ISubscription, displayValuesToRequestData as subDvtrq } from 'shared/types/subscription';
import { auditLogsPage } from './auditLogs';


export interface IListProgramsArgs {
  page: number
  perPage: number
  statuses?: string[] | null
  customerId?: string | null
}

export interface IListSubscriptionsArgs {
  programId?: string
  childCustomerId?: string
  customerId?: string | null
  page: number
  perPage: number
}

export interface IListAssignmentsArgs {
  programId?: string
  generatorId?: string
  page: number
  perPage: number
  customerId?: string | null
}

export const fetchProgramsPage = (qs: string) => API.get<IPaginationResponse<IProgram>>(`/api/v2/retail_programs/?${qs}`);


const programPage: {type: 'Program', id: string} = {type: 'Program', id: 'PAGE'};
const subscriptionPage: {type: 'Subscription', id: string} = {type: 'Subscription', id: 'PAGE'};
const assignmentsPage: {type: 'Assignment', id: string} = {type: 'Assignment', id: 'PAGE'};

const programsQueries = queries.injectEndpoints({
  endpoints: (build) => ({
    paginatePrograms: build.query<IPaginationResponse<IProgram>, IListProgramsArgs>({
      query: (body) => {
        const qs = new URLSearchParams();
        qs.set('page', body.page.toString());
        qs.set('per_page', body.perPage.toString());
        if (body.statuses) {
          body.statuses.forEach(status => qs.append('statuses', status));
        }
        if (body.customerId) {
          qs.set('customer_id', body.customerId);
        }

        return {
          url: `/v2/retail_programs/?${qs}`,
          method: 'GET',
        }
      },

      providesTags: (res) => res ? [programPage, ...res.data.map(p => ({type: 'Program' as const, id: p.id}))] : [],
    }),

    fetchProgram: build.query<{program: IProgram, assignments: IGeneratorAssignment[], subscriptions: ISubscription[]}, {id: string, customerId?: string | null}>({
      query: ({id, customerId}) => `/v2/retail_programs/${id}?${customerId ? `customer_id=${customerId}` : ''}`,
      providesTags: (res) => res ? [{type: 'Program', id: res.program.id}] : [],
    }),

    replaceProgram: build.mutation<{program: IProgram}, {id: string, customerId?: string | null, body: ReturnType<typeof displayValuesToRequestData>}>({
      query: ({id, body, customerId}) => ({
        url: `/v2/retail_programs/${id}?${customerId ? `customer_id=${customerId}` : ''}`,
        body,
        method: 'PUT',
      }),
      invalidatesTags: (_, _a, args) => [{type: 'Program', id: args.id}, programPage, auditLogsPage]
    }),

    createProgram: build.mutation<{program: IProgram}, {customerId?: string | null, body: ReturnType<typeof displayValuesToRequestData>}>({
      query: ({body, customerId}) => ({
        url: `/v2/retail_programs/?${customerId ? `customer_id=${customerId}` : ''}`,
        body,
        method: 'POST',
      }),
      invalidatesTags: [programPage, auditLogsPage],
    }),

    paginateSubscriptions: build.query<IPaginationResponse<ISubscription>, IListSubscriptionsArgs>({
      query: ({page, perPage, customerId, programId, childCustomerId}) => {
        const qs = new URLSearchParams();

        qs.set('page_num', page.toString());
        qs.set('per_page', perPage.toString());

        if (customerId) {
          qs.set('customer_id', customerId);
        }

        if (childCustomerId) {
          qs.set('child_customer_id', childCustomerId);
        }

        if (programId) {
          qs.set('program_id', programId);
        }

        return {
          url: `/v2/retail_programs/subscriptions?${qs}`,
        }
      },
      providesTags: (res) => res ? [subscriptionPage, ...res.data.map(sub => ({type: 'Subscription' as const, id: sub.id}))] : [],
    }),

    createSubscription: build.mutation<{program_customer: ISubscription}, {customerId?: string | null, programId: string, body: ReturnType<typeof subDvtrq>}>({
      query: ({programId, body, customerId}) => ({
        url: `/v2/retail_programs/${programId}/subscribe-customer?${customerId ? `customer_id=${customerId}` : ''}`,
        method: 'POST',
        body,
      }),

      invalidatesTags: (res) => res ? [
        {type: 'Subscription', id: res.program_customer.id},
        {type: 'Program', id: res.program_customer.retail_program_id},
        subscriptionPage,
        auditLogsPage,
      ] : [],
    }),

    replaceSubscription: build.mutation<{program_customer: ISubscription}, {customerId?: string | null, subscriptionId: string, body: ReturnType<typeof subDvtrq>}>({
      query: ({subscriptionId, body, customerId}) => ({
        url: `/v2/retail_programs/subscriptions/${subscriptionId}?${customerId ? `customer_id=${customerId}` : ''}`,
        method: 'PATCH',
        body,
      }),

      invalidatesTags: (res) => res ? [
        {type: 'Subscription', id: res.program_customer.id},
        {type: 'Program', id: res.program_customer.retail_program_id},
        subscriptionPage,
        auditLogsPage,
      ] : [],
    }),

    deleteSubscription: build.mutation<null,{programId: string, subscriptionId: string, customerId?: string | null}>({
      query: ({subscriptionId, customerId}) => ({
        url: `/v2/retail_programs/subscriptions/${subscriptionId}?${customerId ? `customer_id=${customerId}` : ''}`,
        method: 'DELETE',
        responseHandler: (response) => response.text(),
      }),

      // TODO: does the backend need to return info about the sub so I can invalidate the program?
      invalidatesTags: (res, err, req) => [programPage, subscriptionPage, auditLogsPage, {type: 'Program', id: req.programId}],
    }),

    paginateAssignments: build.query<IPaginationResponse<IGeneratorAssignment>, IListAssignmentsArgs>({
      query: ({page, perPage, generatorId, programId, customerId}) => {
        const qs = new URLSearchParams();

        qs.set('page_num', page.toString());
        qs.set('per_page', perPage.toString());

        if (generatorId) {
          qs.set('generator_id', generatorId);
        }

        if (programId) {
          qs.set('program_id', programId);
        }

        if (customerId) {
          qs.set('customer_id', customerId);
        }

        return {
          url: `/v2/retail_programs/assignments?${qs}`,
        }
      },
      providesTags: (res) => res ? [assignmentsPage, ...res.data.map(assign => ({type: 'Assignment' as const, id: assign.id}))] : [],
    }),

    createAssignment: build.mutation<{program_generator: IGeneratorAssignment}, {programId: string, customerId?: string | null, body: ReturnType<typeof assignDvtrd>}>({
      query: ({programId, body, customerId}) => ({
        url: `/v2/retail_programs/${programId}/assign-generator?${customerId ? `customer_id=${customerId}` : ''}`,
        method: 'POST',
        body,
      }),

      invalidatesTags: (res) => res ? [
        {type: 'Assignment', id: res.program_generator.id},
        {type: 'Program', id: res.program_generator.retail_program_id},
        assignmentsPage,
        auditLogsPage,
      ] : [],
    }),

    deleteAssignment: build.mutation<null, {assignmentId: string, programId: string, customerId?: string | null}>({
      query: ({assignmentId, customerId}) => ({
        url: `/v2/retail_programs/assignments/${assignmentId}?${customerId ? `customer_id=${customerId}` : ''}`,
        method: 'DELETE',
        responseHandler: (response) => response.text(),
      }),

      invalidatesTags: (res, err, req) => [{type: 'Program', id: req.programId}, assignmentsPage, auditLogsPage],

    }),
  }),
  overrideExisting: false,
});


export const {
  usePrefetch,
  usePaginateProgramsQuery,
  useFetchProgramQuery,
  useReplaceProgramMutation,
  useCreateProgramMutation,
  usePaginateSubscriptionsQuery,
  useCreateSubscriptionMutation,
  useReplaceSubscriptionMutation,
  useDeleteSubscriptionMutation,
  usePaginateAssignmentsQuery,
  useCreateAssignmentMutation,
  useDeleteAssignmentMutation,
} = programsQueries;