import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ICustomer } from "shared/types/customer";

interface ICustomerSlice {
  byId: Record<string, ICustomer>
}

export interface IListCustomersArgs {
  limit: number
  offset: number
  statuses?: string[]
}


const initialState: ICustomerSlice = {
  byId: {},
}


const customersSlice = createSlice({
  name: 'amp__customers',
  initialState,
  reducers: {
    receiveCustomers: (state, action: PayloadAction<ICustomer[]>) => {
      action.payload.forEach(cust => {
        state.byId[cust.id] = cust;
      })
    },
  },
});


export const { receiveCustomers } = customersSlice.actions;
export default customersSlice.reducer;