import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { bootToLogin } from './index';


const responseHandler = (response: Response) => {
  if (response.status === 401) {
    bootToLogin();
    return Promise.reject(response);
  } else {
    return response.json();
  }
};

const prepareHeaders = (headers: Headers) => {
  headers.set('X-App-Version', process.env.REACT_APP_VERSION || 'no-version');
  headers.set('Accept-Encoding', 'gzip');
  return headers;
}


export const queries = createApi({
  reducerPath: 'apiQueries',

  tagTypes: [
    'Generator',
    'Customer',
    'Program',
    'Certificate',
    'User',
    'AllocationRun',
    'WorkerJob',
    'Assets',
    'Connector',
    'Customer Forecast',
    'Asset Forecast',
    'CertPropDef',
    'Subscription',
    'Assignment',
    'Source',
    'ScheduledTask',
    'AuditLog',
    'DbTables',
    'CustomerReport',
    'AllocationSettlementReview'
  ],

  baseQuery: fetchBaseQuery({
    baseUrl: '/api/',
    prepareHeaders,
    responseHandler,
    credentials: 'include',
  }),

  endpoints: () => ({}),
});

// static data requests are not prefixed with /api/
export const staticDataQueries = createApi({
  reducerPath: 'staticDataQueries',

  // https://github.com/reduxjs/redux-toolkit/blob/07614dd2d2b321eadb4a872d7b6d10c053d22ec3/packages/toolkit/src/query/core/buildMiddleware/cacheCollection.ts#L127-L130
  keepUnusedDataFor: Infinity,

  tagTypes: ['AllocationRunSummary', 'AllocationRunInput', 'AllocationRunCustomerResult', 'AllocationRunStdDeliveryResult', 'AllocationRunProgramResult'],

  // TODO: may need to customize our own base query. handle when s3 returns an HTML doc instead of json
  baseQuery: fetchBaseQuery({
    prepareHeaders,
    responseHandler,
    credentials: 'include',
  }),

  endpoints: () => ({}),
});


// queries to user auth service that are proxied through cloudfront
export const userAuthQueries = createApi({
  reducerPath: 'userAuthApiQueries',

  tagTypes: ['Customer', 'CustomerExternalId', 'CustomerSSOConfig', 'User', 'PublicUser', 'DbTables', 'CustomerParent'],

  baseQuery: fetchBaseQuery({
    baseUrl: '/u/api/',
    prepareHeaders,
    responseHandler,
    credentials: 'include',
  }),

  endpoints: () => ({}),
});
