import { queries } from "shared/api/queries";
import { IPaginationResponse } from "shared/types/api";
import { ICertificatePropertyDefinition, displayValuesToRequestData } from "shared/types/certificateProperties";


export interface IListCertPropDefsArgs {
  page: number
  perPage: number
  customerId?: string | null
}

const certificatesQueries = queries.injectEndpoints({
  endpoints: (build) => ({
    createCertPropDef: build.mutation<{prop_def: ICertificatePropertyDefinition}, {data: ReturnType<typeof displayValuesToRequestData>, customerId?: string | null}>({
      query: ({data, customerId}) => {
        return {
          url: `/v2/certificate_property_definitions/?${customerId ? `customer_id=${customerId}` : ''}`,
          method: 'POST',
          body: data,
        }
      },

      invalidatesTags: [{type: 'CertPropDef', id: 'PAGE'}],
    }),

    paginateCertPropDefs: build.query<IPaginationResponse<ICertificatePropertyDefinition>, IListCertPropDefsArgs>({
      query: (body) => {
        const qs = new URLSearchParams();
        qs.set('page', body.page.toString());
        qs.set('per_page', body.perPage.toString());
        if (body.customerId) {
          qs.set('customer_id', body.customerId);
        }

        return {
          url: `/v2/certificate_property_definitions/?${qs}`,
          method: 'GET',
        }
      },

      providesTags: [{type: 'CertPropDef', id: 'PAGE'}],
    }),

    fetchCertPropDef: build.query<{prop_def: ICertificatePropertyDefinition}, {id: string, customerId?: string | null}>({
      query: ({id, customerId}) => `/v2/certificate_property_definitions/${id}?${customerId ? `customer_id=${customerId}` : ''}`,
    }),

    replaceCertPropDef: build.mutation<{prop_def: ICertificatePropertyDefinition}, {body: ReturnType<typeof displayValuesToRequestData>, id: string, customerId?: string | null}>({
      query: ({id, body, customerId}) => ({
        url: `/v2/certificate_property_definitions/${id}?${customerId ? `customer_id=${customerId}` : ''}`,
        body,
        method: 'PUT',
      }),

      invalidatesTags: [{type: 'CertPropDef', id: 'PAGE'}],
    })
  }),
  overrideExisting: false,
});


export const {
  usePrefetch,
  usePaginateCertPropDefsQuery,
  useCreateCertPropDefMutation,
  useFetchCertPropDefQuery,
  useReplaceCertPropDefMutation,
} = certificatesQueries;