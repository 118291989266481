import { Button, Modal, Stack, Text, TextInput } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useState } from 'react';

import { useSettleAllocationRunMutation } from 'amp/api/allocationRuns';
import { getViewingOpCoId } from 'amp/store/ui/selectors';
import { useAppSelector } from 'store';

const SettlementModal = ({
  onClose,
  isOpen,
  runId,
}: { onClose: () => void, isOpen: boolean, runId: string }) => {
  const [confirmText, setConfirmText] = useState<string>('');

  const oci = useAppSelector(getViewingOpCoId);
  const [settle, settleRes] = useSettleAllocationRunMutation();

  const onRequestSent = () => {
    settle({
      runId: runId,
      customerId: oci,
    }).unwrap().then(() => {
      // TODO: add confetti, needs to be a default "off" user property for "fun mode" of something
      notifications.show({
        title: 'Success',
        message: 'Successfully settled the allocation run',
        color: "teal",
        icon: <IconCheck style={{ width: '20px', height: '20px' }} />,
      });
      onClose();
    }).catch(() => notifications.show({
      title: 'Error',
      message: 'Failed to settle the allocation run',
      icon: <IconX style={{ width: '20px', height: '20px' }} />,
      color: "red",
    }))
  }

  const sendDisabled = confirmText.toLowerCase() !== 'settle';
  return (
    <Modal
      onClose={onClose}
      opened={isOpen}
      title="Settle Allocation"
    >
      <Stack>
        <Text>Once you settle an allocation, you would not be able to undo this action. Allocations with overlapping time periods to this one will no longer be able to be settled.</Text>
        <TextInput label="Type SETTLE  below to confirm" onChange={e => setConfirmText(e.target.value)} />
        <Button
          onClick={onRequestSent}
          loading={settleRes.isLoading}
          disabled={sendDisabled}
          bg={sendDisabled ? undefined : "var(--color-green-2)"}
          c={sendDisabled ? undefined : "white"}
          maw={200}
        >
          Settle Allocation
        </Button>
      </Stack>
    </Modal>
  );
}

export default SettlementModal;