import { Button, Loader, Modal, Stack } from '@mantine/core';
import { IconCheck, IconHistory, IconUserCircle, IconX } from '@tabler/icons-react';
import { ReactNode, useMemo } from 'react';
import { useFetchPublicUserQuery } from 'shared/api/users';
import BaseTable from 'shared/components/Table/baseTable';

import { useUtilityCustomers } from 'shared/store/customers/hooks';
import { IAllocationReportReleaseData, IAllocationRun } from 'shared/types/allocation';
import { timestampToLongDate, timestampToShortDate } from 'shared/utils/dates';


const tableColumns = [
  {
    key: 'name',
    displayValue: 'Customer',
  },
  {
    key: 'released',
    displayValue: 'Status',
  },
  {
    key: 'released_at',
    displayValue: 'Released at',
  },
]


const ReleasedBy = ({release}: {release: IAllocationReportReleaseData}) => {
  const releasedByUserId = release.released_by_actor_id?.split(':', 2)[1];
  const releasedBy = useFetchPublicUserQuery(releasedByUserId || '', {skip: !releasedByUserId});

  let userComp = useMemo(() => {
    if (releasedBy.isLoading) {
      return <Loader size={12} ml={12} />;
    } else if (releasedBy.data) {
      return <span className="report-release-history--value">{releasedBy.data.user.name}</span>;
    } else if (releasedBy.isError) {
      return <em>unknown user</em>;
    }
    return null;
  }, [releasedBy]);

  if (userComp === null) {
    return null;
  }

  return <div className="report-release-history-info--container">
    <IconUserCircle size={16}/>
    <span className="report-release-history--label">Latest release by:</span>
    {userComp}
  </div>
};

const ReleaseStatus = ({release}: {release: IAllocationReportReleaseData}) => {
  if (release.status === "in_progress" || release.status === "not_started") {
    return <div>
        <div className="report-release-history--status"><Loader mr={8} color="var(--color-yellow-1)" size={20} /> Release currently in progress...</div>
        <ReleasedBy release={release} />
      </div>
  } else if (release.status === "success" && release.released_at) {
    return <div>
      <div className="report-release-history--status"><IconCheck style={{marginRight: 8}} size={20} color="teal" /> Successfully released to customers</div>
      {release.released_at && <div className="report-release-history-info--container"><IconHistory size={16} /><span className="report-release-history--label">Last released:</span> <span className="report-release-history--value">{timestampToLongDate(release.released_at, undefined)}</span></div>}
      <ReleasedBy release={release} />
    </div>
  } else {
    return <div>
        <div className="report-release-history--status"><IconX style={{marginRight: 8}} size={20} color="red" />Failed to release to customers</div>
        <ReleasedBy release={release} />
      </div>;
  }
}


const ReportReleaseHistoryModal = ({
  onClose,
  isOpen,
  run,
}: { onClose: (didSucceed: boolean) => void, isOpen: boolean, run: IAllocationRun }) => {
  const availableCustomersRes = useUtilityCustomers();
  const availableCustomers = availableCustomersRes?.data || [];

  const release = run.allocation_config.report_release;

  if (!release) {
    return null;
  }

  const onCloseHandler = (didSucceed = false) => {
    onClose(didSucceed);
  };

  return (
    <Modal
      onClose={onCloseHandler}
      opened={isOpen}
      title="Historical Report Releases to Customers"
    >
        <Stack>
          <ReleaseStatus release={release} />
          {!!release.released_customer_statuses && <BaseTable
            rows={availableCustomers.map(customer => {
              const customerRelease = (release.released_customer_statuses || {})[customer.id];
              let released: ReactNode = <em>not released</em>;
              if (customerRelease?.status === "success") {
                released = "released";
              } else if (customerRelease?.status === "failed") {
                released = "failed to release";
              }
              return {
                id: customer.id,
                name: customer.name,
                released,
                released_at: !customerRelease ? '' : timestampToShortDate(customerRelease.status_updated_at),
              }
            })}
            isLoading={availableCustomersRes.isLoading || availableCustomersRes.isFetching}
            columnNames={tableColumns}
            totalPages={0}
            currentPage={0}
            onPageChange={() => undefined}
          />}

          <Button onClick={() => onCloseHandler()}>
            DONE
          </Button>
        </Stack>
    </Modal>
  );
}

export default ReportReleaseHistoryModal;