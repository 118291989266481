import { API } from 'shared/api';
import { queries } from 'shared/api/queries';
import { IPaginationResponse } from 'shared/types/api';
import { IListAssetEventsResponse } from 'shared/types/assetEvents';
import { IGeneratorAssignment } from 'shared/types/assignment';
import { IGenerator, displayValuesToRequestData } from 'shared/types/generator';
import { auditLogsPage } from './auditLogs';


export interface IListGeneratorsArgs {
  page: number
  perPage: number
  usState?: string | null
  fuelCategory?: string | null
  nameSearch?: string | null
  customerId?: string | null
  assetTypes?: string[] | null
}

interface IFetchGenerationArgs {
  startDate: string
  endDate: string
  resolution: string
  generatorIds: string[]
  customerIds?: string[] | null
}

export const fetchGenerator = (id: string, customerId: string) => API.get(`/api/v2/generators/${id}?customer_id=${customerId}`);

const generatorPage: {type: 'Generator', id: string} = {type: 'Generator', id: 'PAGE'}

const generatorsQueries = queries.injectEndpoints({
  endpoints: (build) => ({
    paginateGenerators: build.query<IPaginationResponse<IGenerator>, IListGeneratorsArgs>({
      query: (body) => {
        const qs = new URLSearchParams();
        qs.set('page', body.page.toString());
        qs.set('per_page', body.perPage.toString());
        if (body.usState) {
          qs.set('us_state', body.usState);
        }

        if (body.fuelCategory) {
          qs.set('fuel_category', body.fuelCategory);
        }

        if (body.nameSearch) {
          qs.set('name', body.nameSearch);
        }

        if (body.customerId) {
          qs.set('customer_id', body.customerId);
        }

        return {
          url: `/v2/generators/?${qs}`,
          method: 'GET',
        }
      },

      providesTags: (res) => res ? [generatorPage, ...res.data.map(gen => ({type: 'Generator' as const, id: gen.id}))] : [],
    }),

    fetchGenerator: build.query<{generator: IGenerator, assignments: IGeneratorAssignment[]}, {id: string, customerId?: string | null}>({
      query: ({id, customerId}) => `/v2/generators/${id}?${customerId ? `customer_id=${customerId}` : ''}`,
      providesTags: (res) => res ? [{type: 'Generator', id: res.generator.id}] : [],
    }),

    // TODO: don't call this with too many generator ids
    fetchGeneration: build.query<IListAssetEventsResponse, IFetchGenerationArgs>({
      query: (body) => {
        const qs = new URLSearchParams();
        qs.set('start', body.startDate);
        qs.set('end', body.endDate);
        qs.set('resolution', body.resolution);
        qs.set('event_type', 'utility_generation');
        body.generatorIds.forEach(id => {
          qs.append('asset_id', id);
        });
        if (body.customerIds) {
          body.customerIds.forEach(id => {
            qs.append('account_id', id)
          });
        }

        return {
          url: `/v2/asset_events/search?${qs}`
        }
      },
    }),

    fetchEmissions: build.query<IListAssetEventsResponse, IFetchGenerationArgs>({
      query: (body) => {
        const qs = new URLSearchParams();
        qs.set('start', body.startDate);
        qs.set('end', body.endDate);
        qs.set('resolution', body.resolution);
        qs.set('event_type', 'utility_generator_emissions');
        body.generatorIds.forEach(id => {
          qs.append('asset_id', id);
        });
        if (body.customerIds) {
          body.customerIds.forEach(id => {
            qs.append('account_id', id)
          });
        }

        return {
          url: `/v2/asset_events/search?${qs}`
        }
      },
    }),


    editGenerator: build.mutation<{generator: IGenerator}, {id: string, customerId?: string | null, body: ReturnType<typeof displayValuesToRequestData>}>({
      query: ({id, body, customerId}) => ({
        url: `/v2/generators/${id}?${customerId ? `customer_id=${customerId}` : ''}`,
        method: 'PUT',
        body,
      }),

      invalidatesTags: (res, _, args) => res ? [generatorPage, auditLogsPage, {type: 'Generator', id: args.id}] : [],
    }),

    bulkFetchGenerators: build.query<{data: IGenerator[]}, {ids: string[], customerId?: string | null}>({
      query: ({ids, customerId}) => {
        const qs = new URLSearchParams();
        ids.forEach(id => {
          qs.append('asset_id', id);
        });

        if (customerId) {
          qs.set('customer_id', customerId);
        }

        return {
          url: `/v2/generators/bulk-find?${qs}`,
        };
      }
    }),

  }),
  overrideExisting: false,
});


export const {
  usePrefetch,
  usePaginateGeneratorsQuery,
  useFetchGeneratorQuery,
  useFetchGenerationQuery,
  useEditGeneratorMutation,
  useBulkFetchGeneratorsQuery,
  useFetchEmissionsQuery,
} = generatorsQueries;