import { Group, Pill, Select, Skeleton } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import { sum, uniq } from 'ramda';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useFetchAggregatedEventsQuery, useFetchAssetEventsQuery } from 'amp/api/assetEvents';
import { useFetchForecastForCustomerQuery } from 'amp/api/assetForecasts';
import { AllocationCookies } from 'amp/outlets/AllocationRuns';
import { useMultipleAssetForecast } from 'amp/store/forecastRuns/hooks';
import { getViewingOpCoId } from 'amp/store/ui/selectors';
import { useFetchStaticDataCookiesQuery } from 'shared/api/users';
import BaseChart from 'shared/components/Chart/baseChart';
import UtcDatePicker from 'shared/components/DatePicker/utcDatePicker';
import BasePaper from 'shared/components/Paper/basePaper';
import { AggregationTypes } from 'shared/types/aggregatedEvents';
import { AssetEventResolution, IEnergyConsumptionData } from 'shared/types/assetEvents';
import { getThisYearEnd, getThisYearStart, ONE_MONTH_MILLIS } from 'shared/utils/dates';
import { hrFloat } from 'shared/utils/math';
import { useAppSelector } from 'store';
import './style.css';

const LoadChart = ({ customerId, isSubAccount = true }: { customerId: string, isSubAccount?: boolean }) => {
  const [params, setParams] = useSearchParams();
  const oci = useAppSelector(getViewingOpCoId);
  const startDateStr = params.get('s') || getThisYearStart().toISOString();
  const endDateStr = params.get('e') || getThisYearEnd().toISOString();

  let resolution = params.get('r') || '1d';
  const isHourlyDisabled = Math.abs(new Date(startDateStr).valueOf() - new Date(endDateStr).valueOf()) > ONE_MONTH_MILLIS;
  if (isHourlyDisabled && resolution === '1h') {
    resolution = '1d';
  }

  const resolutionOptions = [
    { label: `Hourly${isHourlyDisabled ? ' (1 month max)' : ''}`, value: AssetEventResolution.HOUR, disabled: isHourlyDisabled },
    { label: 'Daily', value: AssetEventResolution.DAY },
    { label: 'Monthly', value: AssetEventResolution.MONTH },
    // { label: 'Yearly', value: AssetEventResolution.YEAR },
  ];

  const custConsumptionRes = useFetchAggregatedEventsQuery({
    customerId: customerId,
    startDate: startDateStr,
    endDate: endDateStr,
    resolution,
    aggregationType: AggregationTypes.TOTAL_LOAD,
  }, { skip: isSubAccount });

  const actConsumptionRes = useFetchAssetEventsQuery({
    eventType: 'metered_consumption',
    startDate: startDateStr,
    endDate: endDateStr,
    resolution,
    customerId,
  }, { skip: !isSubAccount });

  const custForecastRes = useFetchForecastForCustomerQuery({
    customerId: oci,
    forecastCustomerId: customerId,
    year: getThisYearEnd().getFullYear(),
  });

  const actsForecastRes = useMultipleAssetForecast(uniq(actConsumptionRes.data?.data.map(d => d.asset_id) || []), getThisYearEnd().getFullYear());
  const forecastData = useMemo(() => {
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);
    if (isSubAccount) {
      return actsForecastRes.data?.filter(({ start_date }) => (
        new Date(start_date) >= startDate && new Date(start_date) <= endDate
      )) || [];
    } else {
      return custForecastRes.data?.event_forecast_batch.data?.results.filter(({ start_date }) => (
        new Date(start_date) >= startDate && new Date(start_date) <= endDate
      )) || [];
    }
  }, [actsForecastRes.data, custForecastRes.data, startDateStr, endDateStr, isSubAccount]);

  const consumptionData = isSubAccount ? actConsumptionRes.data?.data : custConsumptionRes.data?.data;
  const [consumptionSeriesData, totalConsumptionMwh] = useMemo(() => {
    if (!consumptionData) return [];

    const dataByStartDate: Record<string, IEnergyConsumptionData[]> = {};

    const seriesData: number[][] = [];
    let totalKwh = 0;

    consumptionData.forEach(event => {
      if (event.start_date in dataByStartDate) {
        dataByStartDate[event.start_date].push(event.data as IEnergyConsumptionData);
      } else {
        dataByStartDate[event.start_date] = [event.data as IEnergyConsumptionData];
      }
    });

    Object.entries(dataByStartDate).forEach(([startDateStr, eventData]) => {
      const sumConsumedKwh = hrFloat(sum(eventData.map(datum => datum.sum_consumed_kwh)));
      seriesData.push([new Date(startDateStr).valueOf(), hrFloat(sumConsumedKwh / 1000)]);
      totalKwh += sumConsumedKwh;
    });

    return [seriesData, Math.round(totalKwh / 1_000)];
  }, [consumptionData]);

  const [forecastSeriesData, totalForecastYTDMWh, totalForecastMWh] = useMemo(() => {
    if (!forecastData) return [];

    let totalWh = 0;
    let totalWhYTD = 0;

    // Forecast data is always hourly, convert to daily:
    const byDay: Record<string, number> = {};
    forecastData.forEach(event => {
      const date = new Date(event.start_date);
      date.setHours(0);
      date.setMinutes(0);
      if (!byDay[date.toISOString()]) {
        byDay[date.toISOString()] = 0;
      }
      byDay[date.toISOString()] += event.y_axis_value_wh;
      totalWh += event.y_axis_value_wh;
      totalWhYTD += event.y_axis_value_wh;
    });

    const seriesData = Object.entries(byDay).map(([isoString, valWh]) => {
      const dayDate = new Date(isoString);
      return [dayDate.getTime(), valWh / 1_000_000];
    });

    return [seriesData.sort((x1, x2) => x1[0] - x2[0]), Math.round(totalWhYTD / 1_000_000), Math.round(totalWh / 1_000_000)];
  }, [forecastData]);

  const onParamsChange = (params: { name: string, value: string }[]) => {
    setParams(newParams => {
      params.forEach(p => {
        newParams.set(p.name, p.value);
      });
      return newParams;
    });
  };

  const onResolutionChange = (newResolution: string | null) => {
    if (!newResolution) {
      return;
    }
    onParamsChange([{ name: 'r', value: newResolution }]);
  };

  const consumptionChartOptions: Highcharts.Options = {
    series: [
      {
        name: 'Consumption actual',
        data: consumptionSeriesData,
        type: 'area',
        color: '#72B3FF',
        lineWidth: 1,
      },
      {
        name: 'Consumption projection',
        data: forecastSeriesData,
        type: 'line',
        color: 'var(--color-blue-1)',
        dashStyle: 'LongDash',
        lineWidth: 1,
      }
    ],
    legend: {
      enabled: true,
      useHTML: true,
    },
    plotOptions: {
      area: {
        fillColor: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0.25, 'rgba(202, 219, 244, 0.5)'], // start
            [1, 'rgba(202, 219, 244, 0)'] // end
          ]
        },
      },
    },
    tooltip: {
      pointFormatter: function () {
        return `<b>${this.y?.toLocaleString() || ''}</b> MWh`;
      },
    },
    yAxis: [
      {
        title: {
          text: 'Electricity consumption (MWh)',
          useHTML: true,
        }
      }
    ]
  };

  const cookiesRes = useFetchStaticDataCookiesQuery(oci);
  const isLoading = actsForecastRes.loading || actConsumptionRes.isLoading;
  const percentVsForecasted = (totalForecastYTDMWh && totalConsumptionMwh) ? totalConsumptionMwh / totalForecastYTDMWh : 0;
  return (
    <BasePaper
      titleContent={<div>Energy Consumption</div>}
      actions={
        <Group ml="lg" mr="lg" fw="400">
          <Select
            value={resolution}
            data={resolutionOptions}
            onChange={onResolutionChange}
            rightSection={<IconChevronDown size={20} />}
            w="120px"
            className="customer-load-cart--resolution--select"
          />
          <Group p={0} gap={8}>
            <UtcDatePicker
              value={new Date(startDateStr)}
              isStartDate={true}
              maxDate={new Date(endDateStr) || undefined}
              minDate={new Date(2021, 1, 1)}
            />
            -
            <UtcDatePicker
              value={new Date(endDateStr)}
              minDate={new Date(startDateStr) || new Date(2021, 1, 1)}
            />
          </Group>
        </Group>
      }
    >
      <AllocationCookies cfnCookies={cookiesRes.data}>
        <>
          <div className="customer-load-cart--topline-metrics-container">
            <div className="customer-load-cart--topline-metric-container">
              <div className="customer-load-cart--topline-metric-value-container">
                <Skeleton visible={isLoading} width="fit-content">
                  <div className="customer-load-cart--topline-metric-value">{totalConsumptionMwh?.toLocaleString()}</div>
                </Skeleton>
                <div className="customer-load-cart--topline-metric-label">MWh</div>
              </div>
              <div className="customer-load-cart--topline-metric-explanation-container">
                <div className="customer-load-cart--topline-metric-explanation-label">Total consumption YTD</div>
                <Skeleton visible={isLoading}>
                  <div className="customer-load-chart--vs-forecast-container">
                    <Pill ml={8} radius="sm" c={percentVsForecasted <= 1 ? "var(--color-teal-9)" : "var(--color-se-red-1)"} bg={percentVsForecasted <= 1 ? "var(--color-teal-0)" : "var(--color-red-1)"}>
                      {`${percentVsForecasted > 1 ? '+' : ''} ${((percentVsForecasted - 1) * 100).toLocaleString(undefined, { style: 'decimal', minimumFractionDigits: 1, maximumFractionDigits: 1 })} %`}
                    </Pill>
                    <small className="customer-load-chart--vs-forecast-explanation">vs projection</small>
                  </div>
                </Skeleton>
              </div>
            </div>

            <div className="customer-load-cart--topline-metric-container">
              <div className="customer-load-cart--topline-metric-value-container">
                <Skeleton visible={isLoading} width="fit-content">
                  <div className="customer-load-cart--topline-metric-value highlight">{totalForecastMWh?.toLocaleString()}</div>
                </Skeleton>
                <div className="customer-load-cart--topline-metric-label">MWh</div>
              </div>
              <div className="customer-load-cart--topline-metric-explanation-container">
                <div className="customer-load-cart--topline-metric-explanation-label">Consumption year-on-year projection</div>
              </div>

            </div>
          </div>

          <Skeleton visible={isLoading}>
            <BaseChart overrideOptions={consumptionChartOptions} />
          </Skeleton>
        </>
      </AllocationCookies>
    </BasePaper>
  );
}

export default LoadChart;