import { Button, Modal, Stack, Text, TextInput } from '@mantine/core';
import { useState } from 'react';

import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useUpdateAllocationSettlementApprovalMutation } from 'amp/api/allocationRuns';
import { getViewingOpCoId } from 'amp/store/ui/selectors';
import { AllocationSettlementReviewStatus } from 'shared/types/allocation';
import { useAppSelector } from 'store';

const DenialModal = ({
  onClose,
  isOpen,
  reviewId,
}: { onClose: () => void, isOpen: boolean, reviewId: string }) => {
  const [confirmText, setConfirmText] = useState<string>('');

  const oci = useAppSelector(getViewingOpCoId);
  const [update, updateRes] = useUpdateAllocationSettlementApprovalMutation();

  const onRequestSent = () => {
    update({
      reviewId: reviewId,
      newStatus: AllocationSettlementReviewStatus.REJECTED,
      customerId: oci,
    }).unwrap().then(() => {
      notifications.show({
        title: 'Success',
        message: 'Successfully declined the allocation settlement approval',
        color: "teal",
        icon: <IconCheck style={{ width: '20px', height: '20px' }} />,
      });
      onClose();
    }).catch(() => notifications.show({
      title: 'Error',
      message: 'Failed to deny the allocation settlement approval',
      icon: <IconX style={{ width: '20px', height: '20px' }} />,
      color: "red",
    }))
  }

  const sendDisabled = confirmText.toLowerCase() !== 'deny';
  return (
    <Modal
      onClose={onClose}
      opened={isOpen}
      title="Decline Allocation"
    >
      <Stack>
        <Text>Once you decline this allocation, the user who created this request for approval will no longer be able to settle the allocation.</Text>
        <TextInput label="Type DENY below to confirm" onChange={e => setConfirmText(e.target.value)} />
        <Button
          onClick={onRequestSent}
          loading={updateRes.isLoading}
          disabled={sendDisabled}
          bg={sendDisabled ? undefined : "var(--color-se-red-1)"}
          c={sendDisabled ? undefined : "white"}
          maw={200}
        >
          Deny
        </Button>
      </Stack>
    </Modal>
  );
}

export default DenialModal;