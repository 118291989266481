import { Avatar, Box, Button, Center, Group, Menu, Pagination, Skeleton, Table, Text, TextInput, Tooltip } from '@mantine/core';
import { usePagination } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { IconAlertTriangle, IconArchive, IconArrowBack, IconCaretDown, IconCaretUp, IconCheck, IconCircleDashedX, IconDots, IconHistory, IconPencil, IconRosette, IconThumbDown, IconThumbUp, IconUserPlus, IconX } from '@tabler/icons-react';
import { KeyboardEvent, MouseEvent, useMemo, useState } from 'react';
import { createSearchParams, useSearchParams } from 'react-router-dom';

import { useListReviewsForAllocationQuery, usePatchAllocationRunMutation } from 'amp/api/allocationRuns';
import { AmpLink } from 'amp/components/Link';
import { useAmpNav } from 'amp/hooks';
import { useUtilityAllocationRuns } from 'amp/store/allocationRuns/hooks';
import { getAllocationRunStartAndEnd } from 'amp/store/allocationRuns/selectors';
import { getViewingOpCoId } from 'amp/store/ui/selectors';
import { useBulkFetchUsersQuery, useFetchPublicUserQuery } from 'shared/api/users';
import BasePaper from 'shared/components/Paper/basePaper';
import RoleRequired from 'shared/components/RoleRequired/roleRequired';
import { CustomPaginationItem } from 'shared/components/Table/baseTable';
import { AllocationCanBeSettledReason, AllocationRunStatus, AllocationSettlementReviewStatus, getAllocationStatusText, IAllocationRun, IAllocationSettlementReview } from 'shared/types/allocation';
import { IUser, UserRole } from 'shared/types/user';
import { timestampToHoursMinutes, timestampToNumericDate, toNumericDateString } from 'shared/utils/dates';
import { snakeToTitle } from 'shared/utils/strings';
import { useAppSelector } from 'store';
import DeleteAllocationModal from './deleteAllocationModal';
import ReleaseCustomerReportModal from './releaseCustomerReportModal';
import ReportReleaseHistoryModal from './reportReleaseHistoryModal';
import './style.css';


// TODO: are there other places we could use this and the other functions outside the component?
const getIconForReview = (settlementReview: IAllocationSettlementReview) => {
  switch (settlementReview.status) {
    case AllocationSettlementReviewStatus.APPROVED:
      return <Avatar bg="var(--color-teal-2)" size={24}>
        <IconThumbUp size={16} color="var(--color-green-2)" />
      </Avatar>;
    case AllocationSettlementReviewStatus.REJECTED:
      return <Avatar bg="var(--color-red-1)" size={24}>
        <IconThumbDown size={16} color="var(--color-red-3)" />
      </Avatar>;
    case AllocationSettlementReviewStatus.DECLINED:
      // this icon isn't in the designs
      return <Avatar bg="var(--color-neutral-8)" size={24}>
        <IconCircleDashedX size={16} color="var(--color-black-2)" />
      </Avatar>;
    case AllocationSettlementReviewStatus.RESCINDED:
      // this icon isn't in the designs
      return <Avatar bg="var(--color-neutral-8)" size={24}>
        <IconArrowBack size={16} color="var(--color-black-2)" />
      </Avatar>;
    case AllocationSettlementReviewStatus.PENDING:
      // we don't want to show an icon for a pending review
      return <></>;
  }
}

const getIconForAllocationStatus = (allocation: IAllocationRun) => {
  // This only handles the statuses that we render in the expanded rows, some statuses shouldn't show here.
  switch (allocation.status) {
    case AllocationRunStatus.SETTLED:
      // this icon isn't in the designs
      return <Avatar bg="var(--color-teal-2)" size={24}>
        <IconRosette size={16} color="var(--color-green-2)" />
      </Avatar>;
    case AllocationRunStatus.SETTLEMENT_APPROVED:
      return <Avatar bg="var(--color-teal-2)" size={24}>
        <IconCheck size={16} color="var(--color-green-2)" />
      </Avatar>;
    case AllocationRunStatus.SETTLEMENT_AUTO_REJECTED:
      return <Avatar bg="var(--color-red-1)" size={24}>
        <IconAlertTriangle size={16} color="var(--color-red-3)" />
      </Avatar>;
    case AllocationRunStatus.SETTLEMENT_REJECTED:
      // this icon isn't in the designs
      return <Avatar bg="var(--color-red-1)" size={24}>
        <IconX size={16} color="var(--color-red-3)" />
      </Avatar>;
    default:
      return <></>;
  }
}

const getTextForAllocationStatus = (allocation: IAllocationRun) => {
  // This only handles the statuses that we render in the expanded rows, some statuses shouldn't show here.
  switch (allocation.status) {
    case AllocationRunStatus.SETTLED:
      return <Text fz={12}>This allocation has been <b>settled</b>.</Text>;
    case AllocationRunStatus.SETTLEMENT_APPROVED:
      return <Text fz={12}>This allocation has been <b>approved</b> and is ready to be settled.</Text>;
    case AllocationRunStatus.SETTLEMENT_AUTO_REJECTED:
      return <Text fz={12}>This allocation has been <b>auto-rejected</b> because of a conflict.</Text>;
    case AllocationRunStatus.SETTLEMENT_REJECTED:
      return <Text fz={12}>This allocation has been <b>rejected</b> by a reviewer and cannot be settled.</Text>;
    default:
      return <></>;
  }
}

const makeAllocationStatusText = (allocationRun: IAllocationRun) => {
  const { text, background, color } = getAllocationStatusText(allocationRun);
  return <Center>
    <Box bg={background} className="allocation-row--status-box">
      <Text fz={12} c={color}>{text}</Text>
    </Box>
  </Center>
}

const shouldRenderStatusRow = (allocation: IAllocationRun) => {
  const validStatuses = [AllocationRunStatus.SETTLED, AllocationRunStatus.SETTLEMENT_APPROVED, AllocationRunStatus.SETTLEMENT_AUTO_REJECTED, AllocationRunStatus.SETTLEMENT_REJECTED];
  return validStatuses.includes(allocation.status);
}

const shouldDisableReleaseReport = (allocation: IAllocationRun) => {
  const enabledStatuses = [AllocationRunStatus.FINISHED, AllocationRunStatus.SETTLEMENT_APPROVED, AllocationRunStatus.SETTLED, AllocationRunStatus.IN_SETTLEMENT_REVIEW];
  return !enabledStatuses.includes(allocation.status);
}

const AllocationsTableRow = ({ allocationRun }: { allocationRun: IAllocationRun }) => {
  const nav = useAmpNav();
  const startAndEnd = useAppSelector(s => getAllocationRunStartAndEnd(s, allocationRun.id));
  const [editingNote, setEditingNote] = useState(false);
  const [editedNote, setEditedNote] = useState(allocationRun.description || '');
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [contextMenuOpen, setContextMenuOpen] = useState<boolean>(false);
  const [settlementReviewOpen, setSettlementReviewOpen] = useState<boolean>(false);
  const [reportReleaseModalOpen, setReportReleaseModalOpen] = useState<boolean>(false);
  const [releaseHistoryModalOpen, setReleaseHistoryModalOpen] = useState<boolean>(false);
  const viewPastReleasesDisabled = useMemo(() => !allocationRun.allocation_config.report_release?.released_at, [allocationRun])

  const [patch, patchRes] = usePatchAllocationRunMutation();
  const oci = useAppSelector(getViewingOpCoId);

  const createdByUserRes = useFetchPublicUserQuery(allocationRun.created_by, { skip: !allocationRun.created_by });
  const createdBy = createdByUserRes.data?.user?.name;

  const settlementReviewsRes = useListReviewsForAllocationQuery({ id: allocationRun.id, customerId: allocationRun.customer_id });
  const settlementReviews = settlementReviewsRes.data?.data || [];
  const reviewerIds = settlementReviews.map(sr => sr.reviewer_id);
  if (settlementReviews.length > 0) {
    reviewerIds.push(settlementReviews[0].requested_by_id);
  }
  const reviewersRes = useBulkFetchUsersQuery(reviewerIds, { skip: !reviewerIds.length });

  const reviewersById = useMemo(() => {
    const reviewers: Record<string, IUser> = {};
    reviewersRes.data?.data.forEach(reviewer => reviewers[reviewer.id] = reviewer);
    return reviewers;
  }, [reviewersRes.data]);

  const onTableRowClick = () => {
    nav({ pathname: `/dashboard/allocation/${allocationRun.id}`, search: createSearchParams({ oci: allocationRun.customer_id }).toString() });
  };

  const onStopEditing = () => {
    setEditingNote(false);
    setEditedNote(allocationRun.description || '');
  };

  const onSubmitEdit = () => {
    patch({ runId: allocationRun.id, customerId: oci, body: { status: allocationRun.status, description: editedNote } })
      .unwrap()
      .then(() => {
        setEditingNote(false);
      })
      .catch(() => notifications.show({
        title: 'Error',
        message: 'Failed to edit the allocation note',
        icon: <IconX style={{ width: '20px', height: '20px' }} />,
        color: "red",
      }))
  }

  const onClickEdit = (e: MouseEvent) => {
    e.stopPropagation();
    setEditingNote(true);
  }

  const onNoteKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      onSubmitEdit();
    }
  }

  const onContextMenuClick = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setContextMenuOpen(!contextMenuOpen);
  }

  const onDeleteClicked = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setDeleteModalOpen(true);
  }

  const onReleaseClicked = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setReportReleaseModalOpen(true);
  }

  const onReleaseHistoryClicked = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setReleaseHistoryModalOpen(true);
  }

  const onReviewToggleClick = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setSettlementReviewOpen(!settlementReviewOpen);
  }

  return (
    <>
      <Table.Tr className="allocation-row--container" onClick={onTableRowClick}>
        <Table.Td className="allocation-row--cell-container" p={0}>
          <Group wrap="nowrap" gap={0}>
            <Box onClick={onReviewToggleClick} className="allocation-row--toggle-container">
              {settlementReviewOpen ? <IconCaretUp size={16} /> : <IconCaretDown size={16} />}
            </Box>
            <div className="allocation-row--date-range">
              {startAndEnd?.start ? <>{toNumericDateString(startAndEnd.start)} - {toNumericDateString(startAndEnd.end)}</> : 'Unknown'}
            </div>
          </Group>
        </Table.Td>
        <Table.Td onClick={(e: MouseEvent) => editingNote ? e.stopPropagation() : e} className="allocation-row--cell-container">
          {editingNote ?
            <div className="allocation-row-edit-note--container">
              <TextInput size="xs" p="4px 8px" fz="14" value={editedNote} onChange={(e) => setEditedNote(e.target.value)} onKeyDown={onNoteKeyDown} />
              <Button size="xs" variant="outline" onClick={onSubmitEdit} loading={patchRes.isLoading} mr="8px" color="var(--color-teal-6)"><IconCheck size={12} /></Button>
              <Button size="xs" variant="outline" onClick={onStopEditing} disabled={patchRes.isLoading} color="red"><IconX size={12} /></Button>
            </div>
            :
            <Tooltip label={allocationRun.description || 'no note'}>
              <Text fz={14} className="allocations-page--note-container">
                {allocationRun.description || <em>no note</em>}
              </Text>
            </Tooltip>
          }
        </Table.Td>
        <Table.Td className="allocation-row--cell-container">
          <Skeleton visible={createdByUserRes.isLoading || createdByUserRes.isFetching}>
            <Text fz={12} ta="center">
              {createdBy || 'Unknown user'}
            </Text>
          </Skeleton>
        </Table.Td>
        <Table.Td className="allocation-row--cell-container">
          <Text fz={12} ta="center">{timestampToNumericDate(allocationRun.created_at)}</Text>
        </Table.Td>
        <Table.Td className="allocation-row--cell-container">
          <Text ta="center" fz={12}>
            {allocationRun.allocation_config.can_be_settled_reason === AllocationCanBeSettledReason.FALSE__HYPOTHETICAL_PROGRAMS ? 'Test' : 'Allocation'}
          </Text>
        </Table.Td>
        <Table.Td className="allocation-row--cell-container">
          {makeAllocationStatusText(allocationRun)}
        </Table.Td>
        <RoleRequired role={UserRole.ADMIN}>
          <Tooltip label="Actions">
            <Table.Td className="allocation-row--context-menu-button" onClick={onContextMenuClick} p={0}>
              <Menu opened={contextMenuOpen} position="bottom-end" onClose={() => setContextMenuOpen(false)} shadow='sm'>
                <Menu.Target>
                  <Box h="100%" w="100%">
                    <IconDots color="var(--color-blue-3)" size={16} />
                  </Box>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item onClick={onClickEdit} leftSection={<IconPencil size={16} color="var(--color-blue-3)" />}>
                    Edit note
                  </Menu.Item>
                  <Menu.Item onClick={onReleaseClicked} disabled={shouldDisableReleaseReport(allocationRun)} leftSection={<IconUserPlus size={16} color="var(--color-blue-3)" />}>
                    Release report
                  </Menu.Item>
                  <Menu.Item onClick={onReleaseHistoryClicked} disabled={viewPastReleasesDisabled} leftSection={<IconHistory size={16} color="var(--color-blue-3)" />}>
                    Report release history
                  </Menu.Item>
                  <Menu.Item onClick={onDeleteClicked} leftSection={<IconArchive size={16} color="var(--color-blue-3)" />}>
                    Delete
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </Table.Td>
          </Tooltip>
        </RoleRequired>
      </Table.Tr>
      {settlementReviewOpen && shouldRenderStatusRow(allocationRun) &&
        <Table.Tr className="allocation-row--expanded-review-row">
          <Table.Td ta="right">
            <Group justify="flex-end">
              {getIconForAllocationStatus(allocationRun)}
            </Group>
          </Table.Td>
          <Table.Td ta="left">
            <Text fz={12}>
              {getTextForAllocationStatus(allocationRun)}
            </Text>
          </Table.Td>
          <Table.Td></Table.Td>
          <Table.Td></Table.Td>
          <Table.Td ta="right" fz={12} c="var(--color-grey-4)" pr={4}>
            {allocationRun.updated_at ? timestampToNumericDate(allocationRun.updated_at) : 'unknown'}
          </Table.Td>
          <Table.Td ta="left" fz={12} c="var(--color-grey-4)" pl={4}>
            {allocationRun.updated_at ? timestampToHoursMinutes(allocationRun.updated_at) : ''}
          </Table.Td>
          <Table.Td></Table.Td>
        </Table.Tr>
      }
      {settlementReviewOpen && settlementReviews.map(sr => (
        <>
          {/* Don't show pending reviews here in the dropdown, only what a user has actually submitted */}
          {sr.status !== AllocationSettlementReviewStatus.PENDING && <Table.Tr className="allocation-row--expanded-review-row" key={sr.id}>
            <Table.Td ta="right">
              <Group justify="flex-end">
                {getIconForReview(sr)}
              </Group>
            </Table.Td>
            <Table.Td ta="left">
              <Text fz={12}>
                {sr.status === AllocationSettlementReviewStatus.RESCINDED && <>
                  <b>{reviewersById[sr.reviewer_id]?.name || 'An unknown user'}</b> had their review rescinded by <b>{reviewersById[sr.requested_by_id]?.name || 'An unknown user'}</b>
                </>}
                {sr.status !== AllocationSettlementReviewStatus.RESCINDED && <>
                  <b>{reviewersById[sr.reviewer_id]?.name || 'An unknown user'}</b> {snakeToTitle(sr.status.toLowerCase())} this allocation.
                </>}
              </Text>
            </Table.Td>
            <Table.Td></Table.Td>
            <Table.Td></Table.Td>
            <Table.Td ta="right" fz={12} c="var(--color-grey-4)" pr={4}>
              {sr.updated_at ? timestampToNumericDate(sr.updated_at) : 'unknown'}
            </Table.Td>
            <Table.Td ta="left" fz={12} c="var(--color-grey-4)" pl={4}>
              {sr.updated_at ? timestampToHoursMinutes(sr.updated_at) : ''}
            </Table.Td>
            <Table.Td></Table.Td>
          </Table.Tr>
          }
        </>
      ))}
      {settlementReviewOpen && settlementReviews.length > 0 && (
        <Table.Tr className="allocation-row--expanded-review-row">
          <Table.Td ta="right">
            <Group justify="flex-end">
              <Avatar bg="var(--color-black-2)" color="#FFFFFF" size={24}>
                {reviewersById[settlementReviews[0].requested_by_id]?.name.slice(0, 1).toLocaleUpperCase()}
              </Avatar>
            </Group>
          </Table.Td>
          <Table.Td ta="left">
            <Text fz={12}>
              <b>{reviewersById[settlementReviews[0].requested_by_id]?.name || 'An unknown user'}</b> requested approval for this allocation to be settled.
            </Text>
          </Table.Td>
          <Table.Td></Table.Td>
          <Table.Td></Table.Td>
          <Table.Td ta="right" fz={12} c="var(--color-grey-4)" pr={4}>
            {timestampToNumericDate(settlementReviews[0].requested_at)}
          </Table.Td>
          <Table.Td ta="left" fz={12} c="var(--color-grey-4)" pl={4}>
            {timestampToHoursMinutes(settlementReviews[0].requested_at)}
          </Table.Td>
          <Table.Td></Table.Td>
        </Table.Tr>
      )}
      {/* TODO: these modals can trigger warnings in the console locally about not setting state on an component that isn't being rendered */}
      <DeleteAllocationModal isOpen={deleteModalOpen} onClose={() => setDeleteModalOpen(false)} runId={allocationRun.id} />
      <ReleaseCustomerReportModal isOpen={reportReleaseModalOpen} onClose={() => setReportReleaseModalOpen(false)} runId={allocationRun.id} />
      {!!allocationRun.allocation_config.report_release && <ReportReleaseHistoryModal isOpen={releaseHistoryModalOpen} onClose={() => setReleaseHistoryModalOpen(false)} run={allocationRun} />}
    </>
  );
}

const AllocationsView = () => {
  const [params, setSearchParams] = useSearchParams();

  // TODO: make this logic a util function or hook
  const page = isNaN(parseInt(params.get('p') || '1')) ? 1 : parseInt(params.get('p') || '1');
  const perPage = isNaN(parseInt(params.get('ps') || '10')) ? 10 : parseInt(params.get('ps') || '10');
  const statuses = [
    AllocationRunStatus.ERROR,
    AllocationRunStatus.NOT_STARTED,
    AllocationRunStatus.IN_PROGRESS,
    AllocationRunStatus.FINISHED,
    AllocationRunStatus.PAUSED,
    AllocationRunStatus.IN_SETTLEMENT_REVIEW,
    AllocationRunStatus.SETTLEMENT_APPROVED,
    AllocationRunStatus.SETTLEMENT_AUTO_REJECTED,
    AllocationRunStatus.SETTLEMENT_REJECTED,
    AllocationRunStatus.SETTLED,
  ]
  const allocationRes = useUtilityAllocationRuns({ page, perPage, statuses });
  const totalItems = allocationRes.pagination?.total_items || 0;
  const totalPages = allocationRes.pagination?.last || 0;
  const pagination = usePagination({ total: totalPages, page });

  const onParamChange = (paramName: string, paramValue: string | null) => {
    setSearchParams(newParams => {
      // reset the page when search params change
      if (paramName !== 'p') {
        newParams.delete('p');
      }

      if (paramValue === null) {
        newParams.delete(paramName);
      } else {
        newParams.set(paramName, paramValue);
      }
      return newParams;
    });
  };

  const onPageChange = (newPage: number) => {
    onParamChange('p', newPage.toString());
  };

  const rows = allocationRes.data || [];
  return (
    <>
      <Group justify="space-between" p="32px" pb="24px">
        <div className="allocations-page--allocations-title">
          Allocation Management Console
        </div>
        <RoleRequired role={UserRole.ADMIN}>
          <AmpLink to="/dashboard/allocation/create">
            <Button size="sm" className="allocations-page--create-button">
              Create Allocation
            </Button>
          </AmpLink>
        </RoleRequired>
      </Group>
      <div className="allocations-page--scroll-area">
        <BasePaper titleContent="History">
          <Table>
            <Table.Thead>
              <Table.Tr className="allocations-page--header-row">
                <Table.Th w="20%">Allocation period</Table.Th>
                <Table.Th w="40%">Note</Table.Th>
                <Table.Th w="10%">
                  <Text ta="center" fw={600} fz={14}>Created by</Text>
                </Table.Th>
                <Table.Th w="10%">
                  <Text ta="center" fw={600} fz={14}>Creation date</Text>
                </Table.Th>
                <Table.Th w="10%">
                  <Text ta="center" fw={600} fz={14}>Type</Text>
                </Table.Th>
                <Table.Th w="10%">
                  <Text ta="center" fw={600} fz={14}>Status</Text>
                </Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {rows.map(row => <AllocationsTableRow key={row.id} allocationRun={row} />)}
            </Table.Tbody>
          </Table>
          <div className="base-table--footer-container">
            <Text pos="absolute" left={0} c="var(--color-grey-4)" fz="12px">{totalItems.toLocaleString()} Total rows</Text>
            <Pagination.Root
              className="base-table--pagination-container"
              color="rgb(var(--singularity-green-rgb))"
              total={rows.length}
              value={page}
              onChange={onPageChange}
              ta="center"
            >
              <Group gap={5}>
                {totalPages !== 0 && <Pagination.Previous />}
                {pagination.range.map((page, index) => <CustomPaginationItem key={`${page}${index}`} onClick={onPageChange} active={pagination.active === page} page={page} index={index} onMouseEnter={() => { }} />)}
                {totalPages !== 0 && <Pagination.Next />}
              </Group>
            </Pagination.Root>
          </div>
        </BasePaper>
      </div>
    </>
  );
};

export default AllocationsView;