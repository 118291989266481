import { createSelector } from "reselect";
import { AggregationTypes } from "shared/types/aggregatedEvents";
import { IPagination } from "shared/types/api";
import { nMinutesAgo } from "shared/utils/dates";
import { RootState } from "store";

const root = (state: RootState) => state.amp__generator;

const byId = createSelector(
  root,
  (state) => state.byId,
);

const sourceById = createSelector(
  root,
  (state) => state.sourceById,
);

export const getGeneratorById = createSelector(
  byId,
  (_: RootState, generatorId: string) => generatorId,
  (gensById, generatorId) => gensById[generatorId]
);

export const getGeneratorsById = createSelector(
  byId,
  (_: RootState, generatorIds: string[]) => generatorIds,
  (gensById, generatorIds) => generatorIds.map(genId => gensById[genId]).filter(x => !!x)
);

const assignmentsById = createSelector(
  root,
  (state) => state.assignmentsById,
);

const assignmentIdsByGenId = createSelector(
  root,
  (state) => state.assignmentIdsByGenId,
);

const assignmentIdsForGeneratorId = createSelector(
  assignmentIdsByGenId,
  (_: RootState, generatorId: string) => generatorId,
  (assignmentsByGenId, generatorId: string) => assignmentsByGenId[generatorId] || [],
)

export const getAssignmentsForGeneratorId = createSelector(
  assignmentIdsForGeneratorId,
  assignmentsById,
  (assignmentIds, byId) => (
    assignmentIds.map(id => byId[id]).filter(x => !!x)
  )
);

const sourcePageResponseByOci = createSelector(
  root,
  (state) => state.sourcesPageResponseByOCI
);

const sourceFacetsResponseByOci = createSelector(
  root,
  (state) => state.sourceFacetsResponseByOCI,
)

const getSourcePageResponseByOci = createSelector(
  sourcePageResponseByOci,
  (_: RootState, oci: string) => oci,
  (pageResponseByOci, oci) => {
    const ociRes = pageResponseByOci[oci];
    if (!ociRes) {
      return {
        data: null,
        fetchFailed: false,
        isFetching: false,
        lastReceived: null,
        pagination: null,
      }
    } else {
      return ociRes;
    }
  }
);

export const getUtilitySourcesPageLoading = createSelector(
  getSourcePageResponseByOci,
  ociRes => ociRes.isFetching,
);

export const getUtilitySources = createSelector(
  sourceById,
  getSourcePageResponseByOci,
  (sourcesById, ociRes) => ociRes.data?.map(rid => sourcesById[rid]) || []
);

export const getUtilitySourcesPagination = createSelector(
  getSourcePageResponseByOci,
  (ociRes) => ociRes.pagination as null | IPagination
)


export const getUtilitySourcesPageError = createSelector(
  getSourcePageResponseByOci,
  ociRes => ociRes.fetchFailed,
);

const getUtilitySourcesPageArgs = createSelector(
  getSourcePageResponseByOci,
  ociRes => ociRes.serializedArgs,
);


const getSourceFacetsResponseByOci = createSelector(
  sourceFacetsResponseByOci,
  (_: RootState, oci: string) => oci,
  (facetsResponseByOci, oci) => {
    const ociRes = facetsResponseByOci[oci];
    if (!ociRes) {
      return {
        data: null,
        fetchFailed: false,
        isFetching: false,
        lastReceived: null,
        pagination: null,
      }
    } else {
      return ociRes;
    }
  }
);


export const getUtilitySourceFacetsLoading = createSelector(
  getSourceFacetsResponseByOci,
  ociRes => ociRes.isFetching,
);

export const getUtilitySourceFacets = createSelector(
  getSourceFacetsResponseByOci,
  (ociRes) => ociRes.data,
);


export const getUtilitySourceFacetsError = createSelector(
  getSourceFacetsResponseByOci,
  ociRes => ociRes.fetchFailed,
);

const getUtilitySourceFacetsArgs = createSelector(
  getSourceFacetsResponseByOci,
  ociRes => ociRes.serializedArgs,
);

export const shouldFetchUtilitySourceFacets = createSelector(
  getSourceFacetsResponseByOci,
  (_: RootState, oci: string, serializedArgs: string | null) => serializedArgs,
  getUtilitySourceFacetsArgs,
  (ociRes, newArgs, previousArgs) => {
    if (!ociRes) {
      return true;
    }
    const isExpired = ociRes.lastReceived === null || ociRes.lastReceived < nMinutesAgo(5);
    if (isExpired && !ociRes.isFetching && !ociRes.fetchFailed) {
      return true
    }
    const didArgsChange = newArgs !== previousArgs;
    if (didArgsChange) {
      return true
    }
    return false;
  }
)


export const shouldFetchUtilitysourcesPage = createSelector(
  getSourcePageResponseByOci,
  (_: RootState, oci: string, serializedArgs: string) => serializedArgs,
  getUtilitySourcesPageArgs,
  (ociRes, newArgs, previousArgs) => {
    if (!ociRes) {
      return true;
    }
    const isExpired = ociRes.lastReceived === null || ociRes.lastReceived < nMinutesAgo(5);
    if (isExpired && !ociRes.isFetching && !ociRes.fetchFailed) {
      return true
    }
    const didArgsChange = newArgs !== previousArgs;
    if (didArgsChange) {
      return true
    }
    return false;
  }
)

const invSummaryByOci = createSelector(
  root,
  (state) => state.inventorySummaryResponseByOCI
);

const getInventorySummaryByOci = createSelector(
  invSummaryByOci,
  (_: RootState, oci: string) => oci,
  (_: RootState, oci: string, aggType: AggregationTypes) => aggType,
  (pageResponseByOci, oci, aggType) => {
    const ociRes = pageResponseByOci[aggType][oci];
    if (!ociRes) {
      return {
        data: null,
        fetchFailed: false,
        isFetching: false,
        lastReceived: null,
        pagination: null,
      }
    } else {
      return ociRes;
    }
  }
);

export const getUtilityInventorySummaryLoading = createSelector(
  getInventorySummaryByOci,
  ociRes => ociRes.isFetching,
);

export const getUtilityInventorySummary = createSelector(
  getInventorySummaryByOci,
  (ociRes) => ociRes.data
);

export const getUtilityInventoryPagination = createSelector(
  getInventorySummaryByOci,
  (ociRes) => ociRes.pagination,
);

export const getUtilityInventorySummaryError = createSelector(
  getInventorySummaryByOci,
  ociRes => ociRes.fetchFailed,
);

const getUtilityInventorySummaryArgs = createSelector(
  getInventorySummaryByOci,
  ociRes => ociRes.serializedArgs,
);


export const shouldFetchUtilityInventorySummary = createSelector(
  getInventorySummaryByOci,
  getUtilityInventorySummaryArgs,
  (_: RootState, oci: string, aggType: AggregationTypes, serializedArgs: string) => serializedArgs,
  (ociRes, previousArgs, newArgs) => {
    if (!ociRes) {
      return true;
    }
    const isExpired = ociRes.lastReceived === null || ociRes.lastReceived < nMinutesAgo(5);
    if (isExpired && !ociRes.isFetching && !ociRes.fetchFailed) {
      return true
    }
    const didArgsChange = newArgs !== previousArgs;
    if (didArgsChange) {
      return true
    }
    return false;
  }
)