import { Badge } from "@mantine/core";
import { useMemo } from "react";

import { getViewingOpCoId } from "amp/store/ui/selectors";
import { getOpcos } from "shared/store/user/selectors";
import { numToRGBString } from "shared/utils/color";
import { useAppSelector } from "store";


const OpcoBadge = ({customerId}: {customerId?: string | null}) => {
  const oci = useAppSelector(getViewingOpCoId);
  const isViewingAsUtility = !oci;
  const opcos = useAppSelector(getOpcos);
  const parentCustomer = useMemo(() => opcos.find(({id}) => customerId === id), [customerId, opcos]);

  if (!customerId) {
    return null;
  }

  if (!isViewingAsUtility) {
    return null;
  }

  if (!parentCustomer) {
    return null;
  }

  return <Badge mt="8px" size="xs" variant='dot' autoContrast color={numToRGBString(parseInt(parentCustomer.id.replaceAll('.', ''), 36))}>{parentCustomer.name}</Badge>
};

export default OpcoBadge;