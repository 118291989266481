import { Button, Group, InputDescription, InputLabel, InputWrapper, Loader, Modal, TextInput } from "@mantine/core";
import { IconCheck, IconX } from "@tabler/icons-react";
import { FormEvent, useState } from "react";

import { useAdminCreateParentMutation, useLazyAdminFetchCustomerQuery } from "admin/api/customers";
import './style.css';


const AddChildCustomerModal = ({
  onClose,
  isOpen,
  parentId,
}: {onClose: (didSucceed: boolean) => void, isOpen: boolean, parentId: string}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [post] = useAdminCreateParentMutation();
  const [customerId, setCustomerId] = useState('');
  const [doesCustomerExist, setDoesCustomerExist] = useState(false);
  const [fetchCustomer, fetchCustomerRes] = useLazyAdminFetchCustomerQuery();

  const checkDoesCustomerExist = async () => {
    if (!customerId) {
      if (doesCustomerExist) {
        setDoesCustomerExist(false)
      }
      return
    }

    try {
      await fetchCustomer(customerId).unwrap();
      setDoesCustomerExist(true);
    } catch (err) {
      setDoesCustomerExist(false);
    }
  }


  const onSubmit = async (e: FormEvent<HTMLFormElement> | undefined) => {
    e?.preventDefault();
    setIsLoading(true);
    if (!doesCustomerExist || fetchCustomerRes.isLoading) {
      return
    }
    try {
      await post({
        parentId,
        childId: customerId,
      }).unwrap();
      onCloseHandler(true);
    } catch (err) {
      console.warn(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onCloseHandler = (didSucceed = false) => {
    setCustomerId('');
    onClose(didSucceed);
  };

  return (
    <Modal
      onClose={onCloseHandler}
      opened={isOpen}
      title="Add a new child to this customer"
    >
      <form onSubmit={onSubmit}>
        <InputWrapper mt={24}>
          <InputLabel>The ID of the child customer to add</InputLabel>
          <InputDescription>Incorrectly setting this is the most common cause of security incidents in software.</InputDescription>
          <TextInput value={customerId} onChange={(e) => setCustomerId(e.target.value)} onBlur={checkDoesCustomerExist}/>
          {fetchCustomerRes.isLoading && <InputDescription><Loader size={8}/> Checking that customer exists</InputDescription>}
          {!fetchCustomerRes.isLoading && doesCustomerExist && <InputDescription><IconCheck color="var(--color-teal-6)" size={8} /> Customer exists: {fetchCustomerRes.data?.customer.name} ({fetchCustomerRes.data?.customer.customer_type})</InputDescription>}
          {!fetchCustomerRes.isLoading && !doesCustomerExist && !fetchCustomerRes.isUninitialized && <InputDescription><IconX color="var(--color-se-red-1)" size={8} /> Customer does not exist</InputDescription>}
        </InputWrapper>

        <Group justify="flex-end" mt="md">
          <Button loading={isLoading} disabled={!doesCustomerExist} type="submit">Submit</Button>
        </Group>
      </form>
    </Modal>
  );
};


export default AddChildCustomerModal;