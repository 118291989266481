import { queries } from "shared/api/queries";
import { IPaginationResponse } from "shared/types/api";
import { IConnector } from "shared/types/connector";


interface IListConnectorsArgs {
  page: number
  perPage: number
  customerId: string
}


const adminConnecrorsQueries = queries.injectEndpoints({
  endpoints: (build) => ({
    adminPaginateConnectors: build.query<IPaginationResponse<IConnector>, IListConnectorsArgs>({
      query: (body) => {
        const qs = new URLSearchParams();
        qs.set('page', body.page.toString());
        qs.set('per_page', body.perPage.toString());
        qs.set('customer_id', body.customerId);

        return {
          url: `/admin/connectors/?${qs}`,
          method: 'GET',
        }
      },

      providesTags: [{type: 'Connector', id: 'PAGE'}],
    }),

    adminFetchConnector: build.query<{data: IConnector}, string>({
      query: (id) => `/admin/connectors/${id}`,
    }),

  }),
  overrideExisting: false,
});


export const {
  useAdminFetchConnectorQuery,
  useAdminPaginateConnectorsQuery,
} = adminConnecrorsQueries;