import { Button, Modal, Stack, Text, TextInput } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useState } from 'react';

import { usePatchAllocationRunMutation } from 'amp/api/allocationRuns';
import { getViewingOpCoId } from 'amp/store/ui/selectors';
import { AllocationRunStatus } from 'shared/types/allocation';
import { useAppSelector } from 'store';

const DeleteAllocationModal = ({
  onClose,
  isOpen,
  runId,
}: { onClose: () => void, isOpen: boolean, runId: string }) => {
  const [confirmText, setConfirmText] = useState<string>('');
  const [patch, patchRes] = usePatchAllocationRunMutation();
  const oci = useAppSelector(getViewingOpCoId);

  const onDeleteClicked = () => {
    patch({ runId, customerId: oci, body: { status: AllocationRunStatus.ARCHIVED } })
      .then(() => notifications.show({
        title: 'Success',
        message: 'Successfully deleted the allocation run',
        color: "teal",
        icon: <IconCheck style={{ width: '20px', height: '20px' }} />,
      }))
      .catch(() => notifications.show({
        title: 'Error',
        message: 'Failed to delete the allocation',
        icon: <IconX style={{ width: '20px', height: '20px' }} />,
        color: "red",
      }))
  }

  return (
    <Modal
      onClose={onClose}
      opened={isOpen}
      title="Delete Allocation"
    >
      <Stack>
        <Text fz={16} c="var(--color-neutral-12)">
          Are you sure you want to delete this allocation run? It will no longer appear in the list of allocations and the results will no longer be accessible.
        </Text>
        <TextInput label="Type DELETE below to confirm" onChange={e => setConfirmText(e.target.value)} />
        <Button onClick={onDeleteClicked} disabled={confirmText.toLowerCase() !== 'delete'} maw={200} loading={patchRes.isLoading}>
          Delete
        </Button>
      </Stack>
    </Modal>
  );
}

export default DeleteAllocationModal;