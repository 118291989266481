import { CheckIcon, Combobox, Group, InputDescription, InputLabel, InputWrapper, Loader, Pill, PillsInput, TagsInput, useCombobox } from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import { useLazyAdminPaginateCustomersQuery } from "admin/api/customers";
import { useEffect, useMemo, useState } from "react";

// as of Feb 10, 2025, these are the names of all the "authorizations" we have for EIX accounts access
const defaultEIXAccountNames = [
  'GPC Data Set',
  'GSA_CityofAtlanta_GeorgiaTech',
  'Linde Inc',
  'Outokumpu Stainless',
  'APC August 2024 Requested Accounts',
  'GPC August 2024 Requested Accounts',
  'MPC August 2024 Requested Accounts'
];

// maybe one day it'll be somebody else
const EIX_INTEGRATION_OWNER = 'Ryan';

// Most of this component's logic was taken from the Manting Comboox example docs
// https://mantine.dev/combobox/?e=SearchableMultiSelect
const CustomerSelect = ({onChange}: {onChange: (customerIds: string[]) => void}) => {
  const [value, setValue] = useState<string[]>([]);
  const [search, setSearch] = useState('');
  const [fetchCustomers, fetchCustomersRes] = useLazyAdminPaginateCustomersQuery();
  const [timeoutId, setTimeoutId] = useState<ReturnType<typeof setTimeout> | null>(null);

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => {
      if (!fetchCustomersRes.isLoading) {
        fetchCustomers({page: 1, perPage: 15, nameSubstr: search, customerType: ['utility_opco']})
          .unwrap()
          .then(() => {
            combobox.resetSelectedOption();
          })
      }
    },
  });

  useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    if (!fetchCustomersRes.isLoading) {
      const tid = setTimeout(() => fetchCustomers({page: 1, perPage: 15, nameSubstr: search, customerType: ['utility_opco']}), 200);
      setTimeoutId(tid);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    }
  }, [fetchCustomers, fetchCustomersRes, search, timeoutId]);

  const handleValueSelect = (val: string) => {
    setValue((current) => {
      const newValue = current.includes(val) ? current.filter((v) => v !== val) : [...current, val]
      onChange(newValue);
      return newValue;
    });
  }

  const handleValueRemove = (val: string) => {
    setValue((current) => {
      const newValue = current.filter((v) => v !== val);
      onChange(newValue);
      return newValue;
    });
  };

  const options = useMemo(() => fetchCustomersRes.data?.data.map((cust) => (
    <Combobox.Option value={cust.id} key={cust.id} active={value.includes(cust.id)}>
      <Group gap="sm">
        {value.includes(cust.id) ? <CheckIcon size={12}/> : null}
        <span>{cust.name} ({cust.id})</span>
      </Group>
    </Combobox.Option>
  )) || [], [fetchCustomersRes, value]);

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={handleValueSelect}
    >
      <Combobox.Target>
        <PillsInput
          onClick={() => combobox.openDropdown()}
          rightSectionPointerEvents="none"
          rightSection={fetchCustomersRes.isLoading ? <Loader size={18} /> : <Combobox.Chevron />}
          onFocus={() => combobox.openDropdown()}
        >
          <Pill.Group>
            {value.map((item) => (
              <Pill key={item} withRemoveButton onRemove={() => handleValueRemove(item)}>
                {item}
              </Pill>
            ))}
            <Combobox.EventsTarget>
              <PillsInput.Field
                onFocus={() => combobox.openDropdown()}
                onBlur={() => combobox.closeDropdown()}
                value={search}
                placeholder="Search values"
                onChange={(event) => {
                  combobox.updateSelectedOptionIndex();
                  setSearch(event.currentTarget.value);
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Backspace' && search.length === 0) {
                    event.preventDefault();
                    handleValueRemove(value[value.length - 1]);
                  }
                }}
              />
            </Combobox.EventsTarget>
          </Pill.Group>
        </PillsInput>
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options>
          {fetchCustomersRes.isLoading ? <Combobox.Empty>Loading....</Combobox.Empty> : options}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};


const CreateEIXBackfillJob = ({
  onStatusChange
}: {onStatusChange: (status: Record<string, unknown>, isStatusValid: boolean) => void}) => {
  const [eixAuths, setEixAuths] = useState(defaultEIXAccountNames);
  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());
  const [apcCustomerIds, setApcCustomerIds] = useState<string[]>([]);
  const [mpcCustomerIds, setMpcCustomerIds] = useState<string[]>([]);
  const [gpcCustomerIds, setGpcCustomerIds] = useState<string[]>([]);

  useEffect(() => {
    const isValid = start.valueOf() <= end.valueOf();

    start.setUTCMinutes(0, 0, 0);
    end.setUTCMinutes(0, 0, 0);

    onStatusChange({
      subscribed_ws_customer_ids: [],
      opco_to_customer_ids: {
        apc: apcCustomerIds,
        mpc: mpcCustomerIds,
        gpc: gpcCustomerIds,
      },
      eix_authorization_names: eixAuths,
      extract_ids_to_status: {},
      extract_ids_to_errors: {},
      start_date_str: start.toISOString(),
      end_date_str: end.toISOString(),
    }, isValid);
  }, [onStatusChange, start, end, eixAuths, apcCustomerIds, mpcCustomerIds, gpcCustomerIds]);

  return <div>
    <DateTimePicker
      mt={8}
      label="Start"
      value={start}
      onChange={d => d && setStart(d)}
      w="180px"
    />

    <DateTimePicker
      mt={8}
      label="End"
      value={end}
      onChange={d => d && setEnd(d)}
      w="180px"
    />

    <InputWrapper mt={12}>
      <InputLabel>APC/MPC/GPC Customer IDs</InputLabel>
      <InputDescription>In the case that we encounter unknown meters or accounts when ingesting opco data, into which customers should we put newly encountered accounts as children?</InputDescription>
    </InputWrapper>

    <InputWrapper mt={4}>
      <InputLabel>APC</InputLabel>
      <CustomerSelect onChange={setApcCustomerIds}/>
    </InputWrapper>

    <InputWrapper mt={4}>
      <InputLabel>MPC</InputLabel>
      <CustomerSelect onChange={setMpcCustomerIds}/>
    </InputWrapper>

    <InputWrapper mt={4}>
      <InputLabel>GPC</InputLabel>
      <CustomerSelect onChange={setGpcCustomerIds}/>
    </InputWrapper>

    <InputWrapper mt={8}>
      <InputLabel>EIX Account Lists</InputLabel>
      <InputDescription>The EIX account lists to filter for the backfill. If you are unsure which you should select, ask {EIX_INTEGRATION_OWNER}.</InputDescription>
      <TagsInput data={defaultEIXAccountNames} value={eixAuths} onChange={setEixAuths} />
    </InputWrapper>
  </div>
}

export default CreateEIXBackfillJob;