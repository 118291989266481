import { API } from "shared/api/index";
import { IPagination } from "shared/types/api";
import { IFetchBootstrapResponse, IPublicUser, IUser, UserPropertyTypes } from "shared/types/user";
import { queries, userAuthQueries } from "./queries";

export const fetchBootstrap = () => API.get<IFetchBootstrapResponse>('/api/v1/users/me/bootstrap');

interface IListUsersArgs {
  page: number
  perPage: number
}

export interface IStaticDataCookiesRes {
  cookies: {
    "CloudFront-Policy": string,
    "CloudFront-Signature": string,
    "CloudFront-Key-Pair-Id": string,
  },
  expires_at: string
}

const userPage: {type: 'User', id: string} = {type: 'User', id: 'PAGE'};

const usersQueries = queries.injectEndpoints({
  endpoints: (build) => ({
    paginateTeammates: build.query<{data: IUser[], meta: {pagination: IPagination}}, IListUsersArgs>({
      query: (body) => {
        const qs = new URLSearchParams();
        qs.set('page', body.page.toString());
        qs.set('per_page', body.perPage.toString());

        return {
          url: `/v1/users/my-teammates?${qs}`,
          method: 'GET',
        }
      },

      providesTags: (res) => res ? [userPage, ...res.data.map(user => ({type: 'User' as const, id: user.id}))] : [],
    }),

    fetchStaticDataCookies: build.query<IStaticDataCookiesRes, string | null | undefined>({
      query: (customerId) => `/v1/users/me/amp-static-data-cookies?${customerId ? `customer_id=${customerId}` : ''}`,
    })
  })
});

const usersAQueries = userAuthQueries.injectEndpoints({
  endpoints: (build) => ({
    upsertMySetting: build.mutation<{user: IUser}, {name: string, value: string, type?: UserPropertyTypes}>({
      query: (body) => ({
        url: '/v1/users/settings',
        method: 'POST',
        body,
      }),

      invalidatesTags: (res) => res ? [userPage, {type: 'User', id: res.user.id}, {type: 'PublicUser', id: res.user.id}]: [],
    }),

    bulkFetchUsers: build.query<{data: IUser[]}, string[]>({
      query: (userIds) => {
        const qs = new URLSearchParams();
        userIds.forEach(uid => qs.append('user_ids', uid));
        return `/v1/users/bulk/fetch-by-id?${qs}`;
      },

      providesTags: (res) => res? res.data.map(user => ({type: 'User', id: user.id})): [],
    }),

    fetchPublicUser: build.query<{ user: IPublicUser }, string>({
      query: (userId) => {
        return `/v1/users/${userId}/public`;
      },

      providesTags: (res) => res ? [{type: 'PublicUser', id: res.user.id}] : [],
    }),
  })
})

export const {
  usePrefetch,
  usePaginateTeammatesQuery,
  useFetchStaticDataCookiesQuery,
} = usersQueries;

export const {
  useUpsertMySettingMutation,
  useBulkFetchUsersQuery,
  useFetchPublicUserQuery,
} = usersAQueries;