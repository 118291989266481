import { Box, Center, Loader, Skeleton } from '@mantine/core';
import { IconMapPin, IconSitemap } from '@tabler/icons-react';
import { Map, Marker } from 'mapbox-gl';
import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import LoadChart from 'amp/components/LoadChart';
import { getAsDOMElement } from 'amp/components/MapIcons/large';
import { useSubscriptionsPage } from 'amp/store/programs/hooks';
import { IStaticDataCookiesRes } from 'shared/api/users';
import BasePaper from 'shared/components/Paper/basePaper';
import { MAPBOXGL_ACCESS_TOKEN } from 'shared/constants/resources';
import { useCustomer } from 'shared/store/customers/hooks';
import { ICustomer, getCustomerAddress, getCustomerDefaultAllocationPctTenThousandths, getCustomerLatLng, getCustomerUtilityAccountId } from 'shared/types/customer';
import './style.css';


const setCookies = ({ cookies, expires_at }: IStaticDataCookiesRes) => {
  const domainWithPort = `.${document.location.host.split('.').slice(-2).join('.')}`;
  const domain = domainWithPort.split(':')[0];
  Object.entries(cookies).forEach(([cookieName, cookieValue]) => {
    document.cookie = `${cookieName}=${cookieValue};expires=${expires_at};path=/;domain=${domain};`;
  });
};

export const CloudfrontCookies = ({ cfnCookies }: { cfnCookies?: IStaticDataCookiesRes }) => {
  useEffect(() => {
    if (cfnCookies) {
      setCookies(cfnCookies);
    }
  }, [cfnCookies]);

  return null;
};


const SubAccountDetails = ({ subAccount }: { subAccount: ICustomer }) => {
  const map = useRef<Map | null>(null);

  // TODO: limit of 50 subscriptions
  const parentSubscriptionsRes = useSubscriptionsPage({ page: 1, perPage: 50, childCustomerId: subAccount.parent_id || '' });
  const subscriptions = parentSubscriptionsRes.data?.filter(sub => {
    const subAccountConfig = sub.data.configuration.sub_account_to_allocation_percent_ten_thousandths;
    return subAccountConfig ? subAccountConfig[subAccount.id] : true;
  }) || [];
  const parentSubscriptionsCount = subscriptions.length;

  const address = getCustomerAddress(subAccount) || 'No address';
  const accountCommitment = getCustomerDefaultAllocationPctTenThousandths(subAccount);

  useEffect(() => {
    if (!map.current) {
      map.current = new Map({
        accessToken: MAPBOXGL_ACCESS_TOKEN,
        container: 'sub-account-page--map-container',
        attributionControl: false,
        interactive: false,
        center: [-83, 33], // [lng, lat] is expected
        zoom: 4.3,
        style: 'mapbox://styles/mapbox/light-v10',
        renderWorldCopies: false,
      });

      const latLng = getCustomerLatLng(subAccount);
      if (latLng) {
        const lng = typeof latLng[1] === 'number' ? latLng[1] : parseFloat(latLng[1]);
        const lat = typeof latLng[0] === 'number' ? latLng[0] : parseFloat(latLng[0]);
        new Marker(getAsDOMElement(false)).setLngLat([lng, lat]).addTo(map.current);
        map.current.setCenter([lng, lat]);
      }
    }
  });

  return (
    <BasePaper titleContent={<div>Sub-account Details</div>}>
      <div className="sub-account-page--details-row">
        <Box h="160px" w="280px">
          <div id="sub-account-page--map-container" />
        </Box>
        <div className="sub-account-page--metadata-container">
          <div className="sub-account-page--account-name">{subAccount.name}</div>
          <div className="sub-account-page--location-row">
            <IconMapPin size="20px" />
            <div className="sub-account-page--location-text">
              {address}
            </div>
          </div>
          <div className="sub-account-page--id-text">
            Sub-account ID: {getCustomerUtilityAccountId(subAccount) || <em>unknown</em>}
          </div>
        </div>
        <div className="sub-account-page--topline-numbers-container">
          <div className="sub-account-page--topline-numbers-row">
            <Skeleton visible={parentSubscriptionsRes.isLoading}>
              <div className="sub-account-page--topline-stat-container">
                <div className="sub-account-page--subscription-count-container">
                  <IconSitemap size={28} color="var(--color-green-2)" />
                  <div className="sub-account-page--topline-stat-number">
                    {parentSubscriptionsCount}
                  </div>
                </div>
                <div className="sub-account-page--topline-stat-subtext">
                  {`Program subscription${parentSubscriptionsCount !== 1 ? 's' : ''}`}
                </div>
              </div>
            </Skeleton>
            <div className="sub-account-page--topline-stat-container">
              <div className="sub-account-page--topline-stat-number">
                {accountCommitment ? (accountCommitment / 10_000) : 0}%
              </div>
              <div className="sub-account-page--topline-stat-subtext">
                Default Subscribed Generation %
              </div>
            </div>
          </div>
        </div>
      </div>
    </BasePaper>
  );
}


export default function CustomerAccountView() {
  const { accountId = '' } = useParams<{ accountId: string }>();
  const subAccountRes = useCustomer(accountId);
  const subAccount = subAccountRes.data;

  if (subAccountRes.isLoading || !subAccount) {
    return <Box w="100%" h={400}>
      <Center>
        <Loader />
      </Center>
    </Box>
  }

  return (
    <div className="customer-account-page--scroll-area">
      <SubAccountDetails subAccount={subAccount} />
      <LoadChart customerId={subAccount.id} />
    </div>
  );
}