import { Box, Stack, Title } from '@mantine/core';

import BaseTable from 'shared/components/Table/baseTable';
import { IGeneratorAssignment } from 'shared/types/assignment';
import { IGenerator } from 'shared/types/generator';
import { IProgram } from 'shared/types/program';
import { timestampToShortDate } from 'shared/utils/dates';

import { AmpLink } from '../Link';
import './style.css';


const assignmentTableColumns = [
  {key: 'program', displayValue: 'Program'},
  {key: 'programDedicationPercent', displayValue: 'Percent of generation dedicated'},
  {key: 'assignmentStart', displayValue: 'Assignment start'},
  {key: 'assignmentEnd', displayValue: 'Assignment end'},
];


export default function GeneratorAssignments({
  assignments,
  programs,
}: { generator: IGenerator, assignments: IGeneratorAssignment[], programs: IProgram[] }) {
  const rows = assignments.map(assignment => {
    const program = programs.find(prog => prog.id === assignment.retail_program_id);
    const startStr = assignment.data.configuration.assignment_start;
    const endStr = assignment.data.configuration.assignment_end;
    return {
      id: assignment.id,
      program: program ? <AmpLink to={`/dashboard/programs/${program?.id}`}>{program?.name}</AmpLink> : <em>Unknown program</em>,
      programDedicationPercent: <div>{assignment.data.configuration.percent_generation_dedicated_ten_thousandths / 10_000}%</div>,
      assignmentStart: startStr ? timestampToShortDate(startStr) : <em>not specified</em>,
      assignmentEnd: endStr ? timestampToShortDate(endStr) : <em>not specified</em>,
    };
  });

  return (
    <Stack>
      <div className="generator-assignment-title--container">
        <Title order={5}>Program assignments</Title>
      </div>
      <Box pos="relative">
        <BaseTable
          noDataMessage="This generator is not assigned to any programs"
          columnNames={assignmentTableColumns}
          rows={rows}
          totalPages={0}
          currentPage={0}
          onPageChange={() => {}}
        />
      </Box>
    </Stack>
  );
}